import React, { Component } from 'react'
import { connect } from 'react-redux'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import CenteredPage from '../uiTools/CenteredPage'
import { withRouter } from 'react-router-dom'
import { fetchUser } from '../../redux/actions'
import { withStyles } from '@material-ui/core/styles'
import axios from 'axios'

const mapStateToProps = state => {
  return { user: state.user }
}

const styles = theme => ({
  paper: {
    padding: '16px',
    width: '300px'
  }

})

const mapDispatchToProps = dispatch => {
  return {
    fetchUser: () => { dispatch(fetchUser()) }
  }
}

class PasswordChangeForm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      oldPassword: '',
      newPassword: '',
      newPasswordConf: ''
    }
  }
  handleChange (event) {
    this.setState({ [event.target.id]: event.target.value })
  }

  goBack () {
    this.props.history.push('/profile')
  }

  async handleSubmit () {
    try {
      await axios.post('/auth/changePassword', this.state)
      this.props.notify('Password changed')
      this.props.history.push('/home')
    } catch (err) {
      this.props.notify('Password change failed')
      console.log(err)
    }
  }

  async catchEnter (e) {
    if (e.key === 'Enter') {
      e.preventDefault()
      await this.handleSubmit()
    }
  }

  render () {
    const { classes } = this.props
    return (
      <div>
        <CenteredPage>
          <Paper className={classes.paper}>
            <TextField
              name='oldPassword'
              label='old password'
              id='oldPassword'
              value={this.state.oldPassword}
              onChange={this.handleChange.bind(this)}
              type='password'
              onKeyPress={this.catchEnter.bind(this)}
            /><br />
            <TextField
              name='newPassword'
              label='new password'
              id='newPassword'
              value={this.state.newPassword}
              onChange={this.handleChange.bind(this)}
              type='password'
              onKeyPress={this.catchEnter.bind(this)}
            /><br />
            <TextField
              name='newPasswordConf'
              label='password confirmation'
              id='newPasswordConf'
              value={this.state.newPasswordConf}
              onChange={this.handleChange.bind(this)}
              type='password'
              onKeyPress={this.catchEnter.bind(this)}
            /><br />
            <Button variant='contained' color='primary' onClick={this.handleSubmit.bind(this)}>
              Update Password
            </Button>
            <Button variant='contained' color='secondary' onClick={this.goBack.bind(this)}>
              Go Back
            </Button>
          </Paper>
        </CenteredPage>
      </div>
    )
  }
}

module.exports = withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(PasswordChangeForm)))
