
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Snackbar from '@material-ui/core/Snackbar'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { clearNotifications } from '../redux/actions'
const mapStateToProps = state => {
  return {
    notifications: state.notifications
  }
}

const mapDispatchToProps = dispatch => {
  return {
    clearNotifications: () => {
      dispatch(clearNotifications())
    }
  }
}
const styles = theme => ({
  close: {
    padding: 100
  }
})

class NotificationBar extends Component {
  constructor (props) {
    super(props)
    this.state = {
      open: false,
      text: ''
    }
  }
  async processQueue () {
    const notifications = this.props.notifications
    if (notifications.length > 0) {
      let notification = notifications[notifications.length - 1]
      await this.setState({ text: notification, open: true })
    }
  }

  componentDidMount () {
    if (this.state.open) {
      this.setState({ open: false })
    } else {
      this.processQueue()
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.notifications !== prevProps.notifications) {
      if (this.state.open) {
        this.setState({ open: false })
      } else {
        this.processQueue()
      }
    }
  }

  handleClose (event, reason) {
    if (reason === 'clickaway') {
      return
    }
    console.log('close')
    console.log(this.props.notifications)
    this.setState({ open: false })
    this.props.clearNotifications()
  }

  handleExited () {
    this.processQueue()
  }
  render () {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        autoHideDuration={3000}
        open={this.state.open}
        onClose={this.handleClose.bind(this)}
        onExited={this.handleExited.bind(this)}
        message={this.state.text}
        action={
          <IconButton onClick={this.handleClose.bind(this)}>
            <CloseIcon />
          </IconButton>
        }
      />
    )
  }
}

module.exports = withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(NotificationBar)))
