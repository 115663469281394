// evan bedser
import React, { Component } from 'react'
import {
  BrowserRouter,
  Route
} from 'react-router-dom'
import { hot } from 'react-hot-loader'
import Sidebar from './Sidebar'
import Topbar from './Topbar'
import Landing from './landing/Landing'
import { Login, Signup } from './Auth'
import Profile from './profile/Profile'
import EmailChangeForm from './profile/EmailChangeForm'
import PasswordChangeForm from './profile/PasswordChangeForm'
import Upload from './Upload'
import Consent from './Consent'
import CenteredPage from './uiTools/CenteredPage'
import { Newsfeed, ArticleForm } from './Newsfeed'
import Map from './Map'
import LocationCards from './location/LocationCards'
import AdminPage from './admin/AdminPage'
import PasswordResetForm from './PasswordResetForm'
import ForgotPasswordForm from './ForgotPasswordForm'
import NotificationBar from './NotificationBar'
import EmailVerified from './EmailVerified'
import EmailNotVerified from './EmailNotVerified'
import green from '@material-ui/core/colors/green'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: green[500]
    },
    secondary: {
      main: green[50]
    }
  }
})

class App extends Component {
  render () {
    return (
      <MuiThemeProvider theme={theme} >
        <BrowserRouter>
          <div>
            <Route exact path='/' component={props =>
              <Landing />
            } />
            <Route exact path='/home' component={props =>
              <div>
                <Sidebar />
                <Topbar />
                <Consent />
                <Map />
                {/* <LocationDetails title='Location Title' /> */}
                <LocationCards />
              </div>
            } />
            <NotificationBar />
            <Route path='/login' component={props =>
              <CenteredPage>
                <Login />
              </CenteredPage>
            } />
            <Route path='/signup' component={props =>
              <CenteredPage>
                <Signup />
              </CenteredPage>
            } />
            <Route path='/profile' component={props =>
              <div>
                <Sidebar />
                <CenteredPage>
                  <Profile />
                </CenteredPage>
              </div>
            } />
            <Route exact path='/changeEmail' component={EmailChangeForm} />
            <Route exact path='/changePassword' component={PasswordChangeForm} />
            <Route exact path='/auth/resetPassword' component={PasswordResetForm} />
            <Route exact path='/auth/forgotPassword' component={ForgotPasswordForm} />
            <Route exact path='/emailVerified' component={EmailVerified} />
            <Route exact path='/emailNotVerified' component={EmailNotVerified} />
            <Route path='/upload' component={Upload} />
            <Route path='/newsfeed' component={Newsfeed} />
            <Route path='/add' component={ArticleForm} />
            <Route path='/admin' component={AdminPage} />
          </div>
        </BrowserRouter>
      </MuiThemeProvider>
    )
  }
}

module.exports = hot(module)(App)
