// sagas.js

import {
  // LOAD_ARTICLES,
  // LOAD_ARTICLES_SUCCESS,
  // ADD_ARTICLE,
  // ADD_ARTICLE_SUCCESS,
  FETCH_USER,
  FETCH_USER_SUCCESS,
  // SET_PHOTO,
  // SET_PHOTO_SUCCESS,
  GET_CURRENT_LOCATION,
  GET_CURRENT_LOCATION_SUCCESS,
  SEARCH,
  SEARCH_SUCCESS,
  ADD_QUESTION_SUCCESS,
  ADD_QUESTION,
  GET_QUESTIONS,
  GET_QUESTIONS_SUCCESS,
  ERROR,
  QUESTION_ARCHIVED,
  QUESTION_UNARCHIVED,
  ARCHIVE_QUESTION,
  EDIT_QUESTION,
  QUESTION_EDITED,
  SELECT_LOCATION,
  SELECT_LOCATION_SUCCESS,
  // HOVER_LOCATION,
  // HOVER_LOCATION_SUCCESS,
  NOTIFY
} from './actions'
import { put, all, call, takeLatest } from 'redux-saga/effects'
import axios from 'axios'

/*
// call to API to get all articles
async function getAllArticlesApi () {
  let articles = await axios.get('/articles/fetchAll')
  return articles.data
}

// saga for getting all articles
function * fetchArticles () {
  try {
    const articles = yield call(getAllArticlesApi)
    yield put({ type: LOAD_ARTICLES_SUCCESS, payload: articles })
  } catch (err) {
    yield put({ type: ERROR, payload: err })
  }
}
*/

/*
// call to api for adding article
async function postArticleApi (article) {
  try {
    // fullArticle gets the user for the author field
    const fullArticle = await axios.post('/articles/add', {
      title: article.title,
      content: article.content,
      photoId: article.photoId
    })

    return fullArticle
  } catch (err) {
    console.log(err)
    return null
  }
}

// saga for adding article
function * postArticle ({ _, payload }) {
  try {
    let article = yield call(postArticleApi, payload)
    article = article.data
    if (article) {
      yield put({ type: ADD_ARTICLE_SUCCESS, payload: article })
    } else {
      yield put({ type: ERROR, payload: 'ADD_ARTICLE FAILED' })
    }
  } catch (err) {
    yield put({ type: ERROR, payload: err })
  }
}
*/

// call to api to get current user
async function fetchUserApi () {
  try {
    const user = await axios.get('/user/fetch')
    return user.data
  } catch (err) {
    // console.log('User not logged in')
    return null
  }
}

// saga to get current user
function * fetchUser () {
  try {
    const user = yield call(fetchUserApi)
    if (user) {
      yield put({ type: FETCH_USER_SUCCESS, payload: user })
    } else {
      yield put({ type: ERROR, payload: 'FETCH_USER failed' })
    }
  } catch (err) {
    yield put({ type: ERROR, payload: err })
  }
}

/*
// call to api for uploading a photo and getting it's id
async function setPhotoApi (file) {
  try {
    const response = await axios.post('/files/upload', file)
    return response.data.id
  } catch (err) {
    console.log(err)
    return null
  }
}

// saga for uploading a photo and setting it's id in the store
function * setPhoto ({ _, payload }) {
  try {
    let photoId = yield call(setPhotoApi, payload)
    if (photoId) {
      yield put({ type: SET_PHOTO_SUCCESS, payload: photoId })
    } else {
      yield put({ type: ERROR, payload: 'SET_PHOTO failed' })
    }
  } catch (err) {
    yield put({ type: ERROR, payload: err })
  }
}
*/

function getCurrentPosition (options = { timeout: 10000 }) {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject, options)
  })
}

async function getCurrentLocationApi () {
  if ('geolocation' in navigator) {
    try {
      const position = await getCurrentPosition()
      const { latitude, longitude } = position.coords
      return { latitude, longitude }
    } catch (err) {
      console.log('location fail')
      return null
    }
  }
  return null
}
// saga for setting the location using geolocation
function * getCurrentLocation () {
  try {
    let location = yield call(getCurrentLocationApi)
    if (location) {
      yield put({ type: GET_CURRENT_LOCATION_SUCCESS, payload: location })
    } else {
      yield put({ type: ERROR, payload: 'GET_CURRENT_LOCATION failed' })
    }
  } catch (err) {
    yield put({ type: ERROR, payload: err })
  }
}

async function getFoursquareSearch (payload) {
  // convert long lat to string for param
  // let {latitude,longitude} = payload.location
  // let ll = latitude.toString() +','+longitude.toString()
  const ne = payload.ne.lat.toString() + ',' + payload.ne.lng.toString()
  const sw = payload.sw.lat.toString() + ',' + payload.sw.lng.toString()
  let url = '/search/browse'
  let response = await axios.get(url, {
    params: {
      // ll: ll,
      query: payload.searchTerms,
      v: '20180323',
      limit: 50,
      ne: ne,
      sw: sw,
      createdLocation: payload.createdLocation
    }
  })
  console.log(response.data)
  return response.data
}

function * search ({ _, payload }) {
  try {
    let locations = yield call(getFoursquareSearch, payload)
    if (locations) {
      yield put({ type: SEARCH_SUCCESS, payload: locations })
    } else {
      yield put({ type: ERROR, payload: 'SEARCH failed' })
    }
  } catch (err) {
    yield put({ type: ERROR, payload: err })
  }
}

async function addQuestionApi (payload) {
  const response = await axios.post('/questions/addQuestion', payload)
  return response.data
}

function * addQuestion ({ _, payload }) {
  try {
    let question = yield call(addQuestionApi, payload)
    if (question) {
      yield put({ type: ADD_QUESTION_SUCCESS, payload: question })
      yield put({ type: NOTIFY, payload: 'Question added!' })
    } else {
      let payload = 'ADD_QUESTION saga failed.'
      yield put({ type: ERROR, payload: payload })
      yield put({ type: NOTIFY, payload: 'Question add failed!' })
    }
  } catch (err) {
    yield put({ type: ERROR, payload: err })
  }
}

async function getQuestionsApi (payload) {
  const response = await axios.get('/questions/getQuestions')
  return response.data
}

function * getQuestions ({ _, payload }) {
  try {
    let questions = yield call(getQuestionsApi, payload)
    if (questions) {
      yield put({ type: GET_QUESTIONS_SUCCESS, payload: questions })
    } else {
      let payload = 'GET_QUESTIONS saga failed. No questions found.'
      yield put({ type: ERROR, payload: payload })
    }
  } catch (err) {
    yield put({ type: ERROR, payload: err })
  }
}

async function archiveQuestionAPi (payload) {
  let { id, value } = payload // don't need to pass index
  const response = await axios.post('/questions/archiveQuestion', { id, value })
  return response.data
}

function * archiveQuestion ({ _, payload }) {
  try {
    const question = yield call(archiveQuestionAPi, payload)
    if (question && payload.value) {
      yield put({ type: QUESTION_ARCHIVED, payload: payload.index })
      yield put({ type: NOTIFY, payload: 'Question archived!' })
    } else if (question && !payload.value) {
      yield put({ type: QUESTION_UNARCHIVED, payload: question })
      yield put({ type: NOTIFY, payload: 'Question restored!' })
    } else {
      let payload = 'ARCHIVE_QUESTION saga failed.'
      yield put({ type: ERROR, payload: payload })
      yield put({ type: NOTIFY, payload: 'Question archive/restore failed!' })
    }
  } catch (err) {
    yield put({ type: ERROR, payload: err })
  }
}

async function editQuestionApi (payload) {
  const response = await axios.post('/questions/updateQuestion', payload)
  return response.data
}

function * editQuestion ({ _, payload }) {
  try {
    const question = yield call(editQuestionApi, payload.data)
    if (question) {
      payload.data = question
      yield put({ type: QUESTION_EDITED, payload })
      yield put({ type: NOTIFY, payload: 'Question saved!' })
    } else {
      let errorMessage = 'EDIT_QUESTION saga failed.'
      yield put({ type: ERROR, payload: errorMessage })
      yield put({ type: NOTIFY, payload: 'Question edit failed to save!' })
    }
  } catch (err) {
    yield put({ type: ERROR, payload: err })
  }
}

async function selectLocationApi (location) {
  const response = await axios.get('/locations/details', {
    params: {
      location
    }
  })
  return response.data
}

// TODO: this will need to be fixed with the new handleing of data
function * selectLocation ({ _, payload }) {
  try {
    let location = yield call(selectLocationApi, payload)
    payload = location
    yield put({ type: SELECT_LOCATION_SUCCESS, payload })
  } catch (err) {
    console.log('error')
    console.log(err)
    yield put({ type: ERROR, payload: err })
  }
}

// function * hoverLocation ({ _, payload }) {
// try {
// let location = yield call(selectLocationApi, payload)
// payload = location
// yield put({ type: HOVER_LOCATION_SUCCESS, payload })
// } catch (err) {
// console.log('error')
// console.log(err)
// yield put({ type: ERROR, payload: err })
// }
// }

export function * rootSaga () {
  yield all([
    // yield takeLatest(LOAD_ARTICLES, fetchArticles),
    // yield takeLatest(ADD_ARTICLE, postArticle),
    yield takeLatest(FETCH_USER, fetchUser),
    // yield takeLatest(SET_PHOTO, setPhoto),
    yield takeLatest(GET_CURRENT_LOCATION, getCurrentLocation),
    yield takeLatest(SEARCH, search),
    yield takeLatest(ADD_QUESTION, addQuestion),
    yield takeLatest(GET_QUESTIONS, getQuestions),
    yield takeLatest(ARCHIVE_QUESTION, archiveQuestion),
    yield takeLatest(EDIT_QUESTION, editQuestion),
    yield takeLatest(SELECT_LOCATION, selectLocation)
  ])
}
