import React, { Component } from 'react'
import axios from 'axios'
import { withRouter } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { connect } from 'react-redux'
import { notify } from '../redux/actions'

const mapDispatchToProps = dispatch => {
  return {
    notify: (text) => { dispatch(notify(text)) }
  }
}

class ForgotPasswordForm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      username: ''
    }
  }

  handleChange (event) {
    this.setState({ [event.target.id]: event.target.value })
  }
  async submit (e) {
    console.log(this.state)
    try {
      const response = await axios.post('/auth/forgotPassword', this.state)
      if (response.data.error) {
        this.props.notify(response.data.error)
      } else {
        this.props.notify('Password reset email sent!')
      }
    } catch (err) {
      console.log(err)
      this.props.notify('Password reset email failed to send')
    }
  }

  catchEnter (e) {
    if (e.key === 'Enter') {
      e.preventDefault()
      this.submitReset()
    }
  }

  render () {
    if (this.props.user) {
      return ''
    }
    return (
      <div>
        <Card>
          <CardContent>
            <Typography variant='title'>Forgot Password</Typography>
            <TextField
              name='username'
              label='username'
              id='username'
              value={this.state.username}
              onChange={this.handleChange.bind(this)}
              type='text'
              onKeyPress={this.catchEnter.bind(this)}
            /><br />
            <Button variant='contained' onClick={this.submit.bind(this)}>
             Send Email
            </Button>
          </CardContent>
        </Card>
      </div>
    )
  }
}

module.exports = withRouter(connect(null, mapDispatchToProps)(ForgotPasswordForm))
