import React, { Component } from 'react'
import LocationDetailsContainer from './location/LocationDetailsContainer'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  getCurrentLocation,
  shareMapBounds,
  selectLocation,
  hoverLocation,
  clearSelectedLocation,
  clearHoveredLocation,
  fetchUser
} from '../redux/actions'
import L from 'leaflet'

const mapStateToProps = state => {
  return {
    currentLocation: state.currentLocation,
    hoveredLocation: state.hoveredLocation,
    mapMarkers: state.mapMarkers,
    locations: state.locations,
    user: state.user
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getCurrentLocation: () => {
      dispatch(getCurrentLocation())
    },
    shareMapBounds: bounds => {
      dispatch(shareMapBounds(bounds))
    },
    selectLocation: location => {
      dispatch(selectLocation(location))
    },
    hoverLocation: location => {
      dispatch(hoverLocation(location))
    },
    clearHoveredLocation: () => {
      dispatch(clearHoveredLocation())
    },
    clearSelectedLocation: () => {
      dispatch(clearSelectedLocation())
    },
    fetchUser: () => {
      dispatch(fetchUser())
    }
  }
}
export const defaultLocation = { longitude: 40.7440, latitude: -74.0324 }

class Map extends Component {
  constructor (props) {
    super(props)
    this.props.getCurrentLocation()
    this.state = {
      centered: false, // has the map been centered yet?
      markers: [],
      mapBounds: null,
      selectedLocation: null
    }
  }
  componentDidMount () {
    // this.props.fetchUser()
    if (!this.props.user) {
      this.props.history.push('/login')
    }

    let { latitude, longitude } = this.props.currentLocation
    if (this.props.currentLocation !== defaultLocation) {
      this.setState({
        centered: true
      })
    }
    const tileServer = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
    // 'https://{s}.tile.openstreetmap.se/hydda/full/{z}/{x}/{y}.png'
    // create leaflet map
    this.map = L.map('map', {
      zoomControl: false,
      center: [latitude, longitude],
      zoom: 16,
      layers: [
        L.tileLayer(tileServer, {
          attribution:
            'Tiles courtesy of <a href="http://openstreetmap.se/" target="_blank">OpenStreetMap Sweden</a> &mdash; Map data &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
        })
      ]
    })

    // setting initial bounds
    let bounds = this.map.getBounds()
    let ne = bounds._northEast
    let sw = bounds._southWest
    this.setState({ mapBounds: { ne, sw } })
    this.props.shareMapBounds({ ne, sw })

    // map events
    // triggers after the map is finished zooming or moving
    this.map.on('moveend', () => {
      let bounds = this.map.getBounds()
      let ne = bounds._northEast
      let sw = bounds._southWest
      this.setState({ mapBounds: { ne, sw } })
      this.props.shareMapBounds({ ne, sw })
    })

    this.map.on('mousedown', () => {
      this.props.clearSelectedLocation()
    })

    this.map.on('mouseover', () => {
      this.props.clearHoveredLocation()
    })
    // create currentLocation icon
    let currentLocation = L.icon({
      iconUrl: 'small-fork-ribbit.svg',
      iconSize: [60, 60]
    })

    // add a marker for the user's current location
    this.marker = L.marker([latitude, longitude], { icon: currentLocation }).addTo(this.map)
  }

  markerForRating (letter) {
    let greenPin = L.icon({
      iconUrl: 'GreenLeaf.svg',
      iconSize: [60, 60]
    })

    let orangePin = L.icon({
      iconUrl: 'OrangeLeaf.svg',
      iconSize: [60, 60]
    })

    let grayPin = L.icon({
      iconUrl: 'GryLeaf.svg',
      iconSize: [60, 60]
    })

    let redPin = L.icon({
      iconUrl: 'RedLeaf.svg',
      iconSize: [60, 60]
    })

    let greenList = ['A']
    let orangeList = ['B', 'C']
    let redList = ['D', 'F']

    if (greenList.includes(letter)) {
      return greenPin
    } else if (orangeList.includes(letter)) {
      return orangePin
    } else if (redList.includes(letter)) {
      return redPin
    } else {
      return grayPin
    }
  }

  largeMarkerForRating (letter) {
    let greenPin = L.icon({
      iconUrl: 'GreenLeaf.svg',
      iconSize: [80, 80]
    })

    let orangePin = L.icon({
      iconUrl: 'OrangeLeaf.svg',
      iconSize: [80, 80]
    })

    let grayPin = L.icon({
      iconUrl: 'GryLeaf.svg',
      iconSize: [80, 80]
    })

    let redPin = L.icon({
      iconUrl: 'RedLeaf.svg',
      iconSize: [80, 80]
    })

    let greenList = ['A', 'B']
    let orangeList = ['C', 'D']
    let redList = ['F']

    if (greenList.includes(letter)) {
      return greenPin
    } else if (orangeList.includes(letter)) {
      return orangePin
    } else if (redList.includes(letter)) {
      return redPin
    } else {
      return grayPin
    }
  }

  async componentDidUpdate ({ currentLocation, mapMarkers, locations, hoveredLocation, user }) {
    if (user !== this.props.user) {
      if (!this.props.user) {
        this.props.history.push('/login')
      }
    }
    // if the location has changed and the map has not been centered
    if (this.props.currentLocation != null && this.props.currentLocation !== currentLocation && !this.state.centered) {
      let { latitude, longitude } = this.props.currentLocation
      if (this.props.currentLocation !== defaultLocation) {
        this.map.panTo([latitude, longitude])
        this.setState({ centered: true })
      }
    }

    if (this.props.hoveredLocation !== hoveredLocation) {
      for (let i in this.state.markers) {
        let marker = this.state.markers[i]
        if (marker.properties.locationId === this.props.hoveredLocation) {
          marker.setIcon(this.largeMarkerForRating(marker.properties.rating))
        } else {
          marker.setIcon(this.markerForRating(marker.properties.rating))
        }
      }
    } else {
      for (let i in this.state.markers) {
        let marker = this.state.markers[i]
        marker.setIcon(this.markerForRating(marker.properties.rating))
      }
    }

    // I know this is currently repetitive but once the markers are not only on
    // the current position, it will be easier to change
    if (this.props.currentLocation !== currentLocation) {
      let { latitude, longitude } = this.props.currentLocation
      this.marker.setLatLng([latitude, longitude])
    }

    if (this.props.locations !== locations) {
      // remove old markers that are stored in the state
      for (let i in this.state.markers) {
        let marker = this.state.markers[i]
        this.map.removeLayer(marker)
      }
      // set the state to empty
      await this.setState({ markers: [] })

      let newMarkers = []
      for (let i in this.props.locations) {
        let location = this.props.locations[i]
        let { lat, lng } = location.location
        console.log('location')
        console.log(location)
        var icon = this.markerForRating(location.letterRating)
        // let hovered = false
        if (this.props.location && this.props.hoveredLocation && this.props.location._id === this.props.hoveredLocation._id) {
          icon.options.iconSize = [200, 200]
          // hovered = true
        }
        let marker = L.marker([lat, lng],
          {
            icon: icon,
            riseOnHover: true,
            riseOffset: 1000
          }
        )

        // TODO: this doesn't seem to be working. not sure why
        //       not a massive issue but a fix would be nice
        //       it doesn't make sense that riseOffset works but this doesn't
        // if (hovered) {
        //   marker.setZIndexOffset(9999999)
        // }

        marker.on('click', () => {
          console.log('clicked marker')
          this.props.selectLocation(location)
          this.setState({ selectedLocation: location })
        })
        let name = location.name
        let address = location.location.address
        let rating = location.letterRating
        // let sRating = location.starRating
        let popupText = '<h1>' + name + '</h1>'
        if (address) {
          popupText += '<p>' + address + '</p>'
        }
        popupText += '<p> Ribbit Rating:' + rating + '</p>'
        // popupText += '<p> Star Rating:' + sRating + '</p>'
        marker.bindPopup(popupText)
        marker.on('mouseover', () => {
          marker.openPopup()
        })
        marker.on('mouseout', () => {
          marker.closePopup()
        })
        marker.properties = {}
        marker.properties.locationId = location._id
        marker.properties.rating = location.letterRating
        marker.addTo(this.map)
        newMarkers.push(marker)
      }
      await this.setState({ markers: [...this.state.markers, ...newMarkers] })
    }
  }

  handleLocationClose () {
    this.props.clearSelectedLocation()
    this.setState({ selectedLocation: null })
  }

  render () {
    const style = {
      width: '100%',
      height: '100%',
      zIndex: '-100',
      position: 'fixed',
      top: '0px',
      bottom: '0px',
      left: '0px',
      right: '0px'
    }
    return (
      <div>
        {this.state.selectedLocation && <LocationDetailsContainer onClose={this.handleLocationClose.bind(this)} />}
        <div id='map' style={style} />
      </div>
    )
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Map))
