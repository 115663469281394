import React from 'react'
import { withRouter } from 'react-router-dom'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import axios from 'axios'
import { notify } from '../../redux/actions'
import { connect } from 'react-redux'

const mapDispatchToProps = dispatch => {
  return {
    notify: (text) => { dispatch(notify(text)) }
  }
}

class BetaKeysDisplay extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      key: ''
    }
  }

  async handleCreate (event) {
    event.preventDefault()
    try {
      const response = await axios.get('/auth/betaKey/create')
      this.props.notify('Beta key added!')
      await this.setState({ key: response.data })
    } catch (err) {
      this.props.notify('Beta key creation failed!')
      console.log(err)
    }
  }

  render () {
    return (
      <Paper>
        <Typography>{this.state.key.key}</Typography>
        <Button
          color='primary'
          variant='contained'
          onClick={this.handleCreate.bind(this)}
        >
          New Key
        </Button>
      </Paper>
    )
  }
}

module.exports = withRouter(connect(null, mapDispatchToProps)(BetaKeysDisplay))

/*
class BetaKeysDisplay extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      keys: []
    }
  }
  async componentWillMount () {
    try {
      // axios request to load beta keys
      let keys = await axios.get('/auth/betaKey/all')
      keys = keys.data
      await this.setState({ keys: keys })
      console.log(this.state)
    } catch (err) {
      console.log(err)
    }
  }

  async handleDelete (event) {
    event.preventDefault()
    const key = event.target.id
    const keys = this.state.keys
    try {
      await axios.delete('/auth/betaKey/delete/' + key)
      const newKeys = keys.filter((x) => x.key !== key)
      await this.setState({ keys: newKeys })
    } catch (err) {
      console.log(err)
    }
  }

  async handleCreate (event) {
    event.preventDefault()
    try {
      const response = await axios.get('/auth/betaKey/create')
      const key = response.data
      const keys = this.state.keys
      await this.setState({ keys: [...keys, key] })
    } catch (err) {
      console.log(err)
    }
  }

  render () {
    let i = 0
    let keys = this.state.keys
    let keyList = keys.map((x) => {
      return (
        <TableRow key={i}>
          <TableCell>{i++}</TableCell>
          <TableCell>{x.key}</TableCell>
          <TableCell>
            <Button id={x.key} color='primary' onClick={this.handleDelete.bind(this)}>
            Delete
            </Button>
          </TableCell>
        </TableRow>
      )
    })
    return (
      <Paper>
        <Button
          color='primary'
          variant='contained'
          onClick={this.handleCreate.bind(this)}
        >
          New Key
        </Button>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Key</TableCell>
              <TableCell>Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {keyList}
          </TableBody>
        </Table>
      </Paper>
    )
  }
}
*/
