
import React, { Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import QuestionAddForm from './QuestionAddForm'
import QuestionDisplay from './QuestionDisplay'
import BetaKeysDisplay from './BetaKeysDisplay'

class AdminPage extends React.Component {
  goHome () {
    this.props.history.push('/home')
  }
  render () {
    return (
      <Fragment>
        <Typography variant='title'>
         Admin Page
        </Typography>
        <Button variant='contained' onClick={this.goHome.bind(this)}>
          Home
        </Button>
        <Typography variant='subheading'>
          Beta Keys
        </Typography>
        <BetaKeysDisplay />
      </Fragment>
    )
  }
}

module.exports = withRouter(AdminPage)
