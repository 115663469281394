import React, { Component } from 'react'
import AppBar from '@material-ui/core/AppBar'
import IconButton from '@material-ui/core/IconButton'
import Toolbar from '@material-ui/core/Toolbar'
import MenuIcon from '@material-ui/icons/Menu'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import axios from 'axios'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { clearUser } from '../redux/actions'

const drawerWidth = 240
const styles = theme => ({
  appBar: {
    background: 'transparent',
    position: 'absolute',
    boxShadow: 'none',
    zIndex: '100',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  hide: {
    display: 'none'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: -drawerWidth
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  },
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    }
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    marginRight: theme.spacing.unit * 2,
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing.unit * 3,
      width: 'auto'
    }
  },
  searchIcon: {
    width: theme.spacing.unit * 9,
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  logo: {
    width: '80%'
  }
})

const mapStateToProps = state => {
  return { user: state.user }
}

const mapDispatchToProps = dispatch => {
  return {
    clearUser: () => { dispatch(clearUser()) }
  }
}

class Sidebar extends Component {
  constructor (props) {
    super(props)
    this.goHome = this.goHome.bind(this)
    this.goLogin = this.goLogin.bind(this)
    this.goNewsfeed = this.goNewsfeed.bind(this)
    this.goProfile = this.goProfile.bind(this)
    this.goArticleForm = this.goArticleForm.bind(this)
    this.handleDrawerClose = this.handleDrawerClose.bind(this)
    this.handleDrawerOpen = this.handleDrawerOpen.bind(this)
    this.goAdmin = this.goAdmin.bind(this)
    // this.openMenu = this.openMenu.bind(this)
    // this.closeMenu = this.closeMenu.bind(this)
    this.state = {
      open: false,
      anchorEl: null,
      rightLabel: 'Login/Signup'
    }
  }

  goHome () {
    this.props.history.push('/home')
    this.handleDrawerClose()
  }

  goArticleForm () {
    this.props.history.push('/add')
    this.handleDrawerClose()
  }
  goLogin () {
    this.props.history.push('/login')
    this.handleDrawerClose()
  }
  goNewsfeed () {
    this.props.history.push('/newsfeed')
    this.handleDrawerClose()
  }
  goProfile () {
    this.props.history.push('/profile')
    this.handleDrawerClose()
  }

  goAdmin () {
    this.props.history.push('/admin')
    this.handleDrawerClose()
  }
  async logout () {
    await axios.get('/auth/logout')
    this.props.clearUser()
  }
  handleDrawerOpen () {
    this.setState({ open: true })
  }

  handleDrawerClose () {
    this.setState({ open: false })
  }

  render () {
    // const { classes, theme } = this.props
    const { classes, user } = this.props
    const { open } = this.state
    let userInfo = (
      <ListItem button onClick={this.goLogin}>
        <ListItemText primary='Login/Signup' />
      </ListItem>
    )
    if (user) {
      userInfo = (
        <span>
          <ListItem button onClick={this.goProfile}>
            <ListItemText primary={'Profile: ' + user.username} />
          </ListItem>
          <Divider />
          <ListItem button onClick={this.logout.bind(this)}>
            <ListItemText primary={'Logout'} />
          </ListItem>
        </span>
      )
    }
    let adminInfo = ''
    if (user && user.admin) {
      adminInfo = (
        <span>
          <Divider />
          <ListItem button onClick={this.goAdmin}>
            <ListItemText primary='Admin Page' />
          </ListItem>
        </span>
      )
    }
    return (
      <div>
        <AppBar
          className={classNames(classes.appBar, {
            [classes.appBarShift]: open
          })}
        >
          <Toolbar disableGutters={!open}>
            <IconButton
              color='inherit'
              aria-label='Open drawer'
              onClick={this.handleDrawerOpen}
              className={classNames(open && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
            {/* <SearchBar /> */}
          </Toolbar>
        </AppBar>
        <Drawer
          className={classes.drawer}
          variant='persistent'
          anchor='left'
          open={open}
          classes={{
            paper: classes.drawerPaper
          }}
        >
          <div className={classes.drawerHeader}>
            {/* <Typography variant='title' onClick={this.goHome}>
              Ribbit Logo Here
            </Typography> */}
            <img className={classes.logo} src={require('./landing/logo.png')} alt='' />
            <IconButton onClick={this.handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <List>
            <ListItem button onClick={this.goHome}>
              <ListItemText primary='Map' />
            </ListItem>
            <Divider />
            {userInfo}
            {adminInfo}
          </List>
        </Drawer>
      </div>
    )
  }
}

module.exports = withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Sidebar)))
