
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

class EmailVerified extends Component {
  handleClick () {
    this.props.history.push('/login')
  }
  render () {
    return (
      <div>
        <Typography variant='title'>Email verified successfully!</Typography>
        <Button
          variant='contained'
          color='secondary'
          onClick={this.handleClick.bind(this)}
        >
          Go to login
        </Button>
      </div>
    )
  }
}

module.exports = withRouter(EmailVerified)
