import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { setPhoto } from '../redux/actions'

const mapStateToProps = state => {
  return { photo: state.photo[state.photo.length - 1] }
}

const mapDispatchToProps = dispatch => {
  return {
    setPhoto: photo => dispatch(setPhoto(photo))
  }
}

class Upload extends Component {
  constructor (props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.state = {
      photoToUpload: '' // used for upload form
    }
  }

  handleChange (event) {
    let file = event.target.files[0]
    this.setState({ photoToUpload: file })
  }

  handleSubmit (event) {
    event.preventDefault()
    let photo = this.state.photoToUpload
    // standard linter doesn't like next line but it's ok
    let data = new FormData() // eslint-disable-line
    data.append('picture', photo)
    photo = data
    this.props.setPhoto(photo)
  }
  render () {
    let PreviewWindow = ''
    if (this.props.photo) {
      PreviewWindow = <img src={'/files/download?id=' + this.props.photo} />
    }
    return (
      <div>
        {PreviewWindow}
        <form onSubmit={this.handleSubmit}>
          <input type='file' id='photoToUpload' onChange={this.handleChange} />
          <button type='submit'>Upload</button>
        </form>
      </div>
    )
  }
}

module.exports = withRouter(connect(mapStateToProps, mapDispatchToProps)(Upload))
