import React, { Component } from 'react'
import axios from 'axios'
import { withRouter } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { connect } from 'react-redux'
import { fetchUser, notify } from '../redux/actions'
import queryString from 'query-string'

const mapStateToProps = state => {
  return { user: state.user }
}
const mapDispatchToProps = dispatch => {
  return {
    fetchUser: () => { dispatch(fetchUser()) },
    notify: (text) => { dispatch(notify(text)) }
  }
}

class PasswordResetForm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      password: '',
      passwordConf: '',
      token: ''
    }
  }
  componentDidMount () {
    const values = queryString.parse(this.props.location.search)
    const token = values.token
    this.setState({ token: token })
  }
  handleChange (event) {
    this.setState({ [event.target.id]: event.target.value })
  }
  async submitReset (e) {
    console.log(this.state)
    try {
      let response = await axios.post('/auth/resetPassword', this.state)
      if (response.data.error) {
        this.props.notify(response.data.error)
      } else {
        this.props.notify('Password change successful')
      }
    } catch (err) {
      this.props.notify('Password change failed')
      console.log(err)
    }
  }

  catchEnter (e) {
    if (e.key === 'Enter') {
      e.preventDefault()
      this.submitReset()
    }
  }

  render () {
    if (this.props.user) {
      return ''
    }
    return (
      <div>
        <Card>
          <CardContent>
            <Typography variant='title'>Reset Password</Typography>
            <TextField
              name='password'
              label='new password'
              id='password'
              value={this.state.password}
              onChange={this.handleChange.bind(this)}
              type='password'
              onKeyPress={this.catchEnter.bind(this)}
            /><br />
            <TextField
              name='passwordConf'
              label='password confirmation'
              id='passwordConf'
              value={this.state.passwordConf}
              onChange={this.handleChange.bind(this)}
              type='password'
              onKeyPress={this.catchEnter.bind(this)}
            /><br />
            <Button variant='contained' onClick={this.submitReset.bind(this)}>
            Reset Password
            </Button>
          </CardContent>
        </Card>
      </div>
    )
  }
}

module.exports = withRouter(connect(mapStateToProps, mapDispatchToProps)(PasswordResetForm))
