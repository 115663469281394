// TODO: refactor these into two seperate files
//       these files can be grouped in an auth folder with reset forms

import React, { Component } from 'react'
// import ReactDom from 'react-dom'
import axios from 'axios'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Typography from '@material-ui/core/Typography'
import { connect } from 'react-redux'
import { fetchUser, notify } from '../redux/actions'

const mapStateToProps = state => {
  return { user: state.user }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchUser: () => { dispatch(fetchUser()) },
    notify: (text) => { dispatch(notify(text)) }
  }
}

const styles = theme => ({
  card: {
    padding: ''
  },
  cardActions: {
    paddingLeft: '0pt'
  },
  button: {
    marginLeft: '0pt',
    marginBottom: '0pt'
  }
})

class Signup extends Component {
  constructor (props) {
    super(props)
    this.state = {
      email: '',
      username: '',
      password: '',
      passwordConf: '',
      betaKey: ''
    }
    this.submitLogin = this.submitLogin.bind(this)
    this.disabledSignup = this.disabledSignup.bind(this)
  }
  componentDidMount () {
    this.props.fetchUser()
    if (this.props.user) {
      this.props.history.push('/home')
    }
  }
  componentDidUpdate ({ user }) {
    if (this.props.user !== user) {
      if (this.props.user) {
        this.props.history.push('/home')
      }
    }
  }

  handleChange (event) {
    this.setState({ [event.target.id]: event.target.value })
  }

  disabledSignup () {
    this.props.notify('Web signup is disabled at this time.')
  }

  async submitLogin () {
    const { username, password } = this.state
    try {
      const response = await axios.post('/auth/adminlogin', { username, password })
      if (response.data.error) {
        this.props.notify(response.data.error)
      } else {
        this.props.fetchUser()
      }
    } catch (err) {
      console.log('error')
      console.log(err)
    }
  }
  async submitSignup (e) {
    let { email, username, password, passwordConf, betaKey } = this.state
    const params = { email, username, password, passwordConf, betaKey }
    try {
      const response = await axios.post('/auth/register', params)
      if (response.data.error) {
        this.props.notify(response.data.error)
      } else {
        this.props.notify('Signup successful!')
        await this.submitLogin()
      }
    } catch (err) {
      console.log('error')
      console.log(err)
    }
  }
  goLogin () {
    this.props.history.push('/')
  }
  catchEnter (e) {
    if (e.key === 'Enter') {
      e.preventDefault()
      // this.submitSignup()
      this.disabledSignup()
    }
  }

  render () {
    const { classes } = this.props
    return (
      <div>
        <Card>
          <CardContent>
            <Typography variant='title'>Signup</Typography>
            <TextField
              name='email'
              label='email'
              id='email'
              value={this.state.email}
              onChange={this.handleChange.bind(this)}
              onKeyPress={this.catchEnter.bind(this)}
            /><br />
            <TextField
              name='username'
              label='username'
              id='username'
              value={this.state.username}
              onChange={this.handleChange.bind(this)}
              onKeyPress={this.catchEnter.bind(this)}
            /><br />
            <TextField
              name='betaKey'
              label='Beta Key'
              id='betaKey'
              value={this.state.betaKey}
              onChange={this.handleChange.bind(this)}
              onKeyPress={this.catchEnter.bind(this)}
            /><br />
            <TextField
              name='password'
              label='password'
              id='password'
              value={this.state.password}
              onChange={this.handleChange.bind(this)}
              type='password'
              onKeyPress={this.catchEnter.bind(this)}
            /><br />
            <TextField
              name='passwordConf'
              label='password confirmation'
              id='passwordConf'
              value={this.state.passwordConf}
              onChange={this.handleChange.bind(this)}
              type='password'
              onKeyPress={this.catchEnter.bind(this)}
            /><br />
            <CardActions className={classes.cardActions}>
              <Button className={classes.button} variant='contained' color='primary' size='small' onClick={this.disabledSignup.bind(this)}>
                signup
              </Button>
              <Button className={classes.button} variant='contained' size='small' onClick={this.goLogin.bind(this)}>
                Login
              </Button>
            </CardActions>
          </CardContent>
        </Card>
      </div>
    )
  }
}

class Login extends Component {
  constructor (props) {
    super(props)
    this.nameChange = this.nameChange.bind(this)
    this.passwordChange = this.passwordChange.bind(this)
    this.submitLogin = this.submitLogin.bind(this)
    this.state = {
      name: '',
      password: ''
    }
  }

  componentDidMount () {
    // this.props.fetchUser()
    if (this.props.user) {
      this.props.history.push('/home')
    }
  }
  componentDidUpdate ({ user }) {
    if (this.props.user !== user) {
      if (this.props.user) {
        this.props.history.push('/home')
      }
    }
  }

  nameChange (e) {
    this.setState({ name: e.target.value })
  }

  passwordChange (e) {
    this.setState({ password: e.target.value })
  }

  async submitLogin (e) {
    const { name, password } = this.state
    try {
      const response = await axios.post('/auth/adminlogin', { username: name, password: password })
      if (response.data.error) {
        this.props.notify(response.data.error)
      } else {
        this.props.fetchUser()
        this.props.notify('Login successful!')
      }
    } catch (err) {
      console.log('error')
      console.log(err)
    }
  }

  goSignup () {
    this.props.history.push('/signup')
  }

  goForgot () {
    this.props.history.push('/auth/forgotPassword')
  }

  catchEnter (e) {
    if (e.key === 'Enter') {
      e.preventDefault()
      this.submitLogin()
    }
  }

  render () {
    const { classes } = this.props
    return (
      <div>
        <Card>
          <CardContent>
            <Typography variant='title'>Admin Login</Typography>
            <TextField
              name='logname'
              label='username'
              value={this.state.name}
              onChange={this.nameChange}
              onKeyPress={this.catchEnter.bind(this)}
            /><br />
            <TextField
              name='logpassword'
              label='password'
              type='password'
              value={this.state.password}
              onChange={this.passwordChange}
              onKeyPress={this.catchEnter.bind(this)}
            /><br />
            <CardActions className={classes.cardActions}>
              <Button className={classes.button} variant='contained' size='small' color='primary' onClick={this.submitLogin}>
                login
              </Button>
              <Button className={classes.button} variant='contained' color='secondary' size='small' onClick={this.goSignup.bind(this)}>
                Sign Up
              </Button>
            </CardActions>
          </CardContent>
        </Card>
      </div>
    )
  }
}

module.exports = {
  Signup: withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Signup))),
  Login: withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Login)))
}
