import React, { Component } from 'react'
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button'
import { withRouter } from 'react-router-dom'
import { fetchUser, clearUser, notify } from '../../redux/actions'
import axios from 'axios'

const mapDispatchToProps = dispatch => {
  return {
    fetchUser: () => { dispatch(fetchUser()) },
    clearUser: () => { dispatch(clearUser()) },
    notify: (text) => { dispatch(notify(text)) }
  }
}

class LogoutButton extends Component {
  async handleChange (event) {
    let url = '/user/togglePrivate'
    await axios.get(url, { params: { value: event.target.checked } })
    this.props.fetchUser()
  }

  async logout () {
    try {
      await axios.get('/auth/logout')
      this.props.clearUser()
      this.props.history.push('/')
      this.props.notify('Logged out')
    } catch (err) {
      this.props.notify('Log out failed')
      console.log(err)
    }
  }

  render () {
    return (
      <Button variant='contained' color='secondary' onClick={this.logout.bind(this)}>
        Logout
      </Button>
    )
  }
}

module.exports = withRouter(connect(null, mapDispatchToProps)(LogoutButton))
