
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Paper from '@material-ui/core/Paper'
import Modal from '@material-ui/core/Modal'
import Button from '@material-ui/core/Button'
import { withRouter } from 'react-router-dom'
import ReviewsList from './ReviewsList'
import LocationTitle from './LocationTitle'
import ReviewSummary from './ReviewSummary'
import QuestionForm from './QuestionForm'

const mapStateToProps = state => {
  return { selectedLocation: state.selectedLocation }
}

function getModalStyle () {
  const top = 20
  const left = 0

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(${left}%, ${top}%)`,
    margin: '0 auto',
    width: '60%',
    height: '40%',
    minHeight: 400,
    padding: 20,
    outline: 'none'
  }
}

class LocationDetailsContainer extends Component {
  constructor (props) {
    super(props)
    this.state = {
      openReview: false
    }
  }

  showReviewQuestions () {
    this.setState({
      openReview: true
    })
  }

  onReviewsClose () {
    this.setState({
      openReview: false
    })
  }

  render () {
    console.log(this.state.openReview)
    console.log(this.props.selectedLocation)
    let swapButton = (
      <Button
        onClick={this.showReviewQuestions.bind(this)}
        variant='contained'
        color='primary'
      >
        Give Feedback
      </Button>
    )
    if (this.state.openReview) {
      swapButton = (
        <Button
          onClick={this.onReviewsClose.bind(this)}
          variant='contained'
          color='primary'
        >
          Show Reviews
        </Button>
      )
    }
    if (this.props.selectedLocation) {
      console.log('selected location')
      console.log(this.props.selectedLocation)
      console.log('user review')
      console.log(this.props.selectedLocation.userReview)
      let reviewCount = this.props.selectedLocation.reviews.length
      if (this.props.selectedLocation.userReview) {
        reviewCount += 1
      }
      return (
        <Modal
          aria-labelledby='location-detail-modal'
          aria-describedby='simple-modal-description'
          open={this.props.selectedLocation !== null}
          onClose={this.props.onClose}
        >
          <Paper style={getModalStyle()}>
            <div style={{ width: '49%', float: 'left' }}>
              <LocationTitle location={this.props.selectedLocation} />
              <ReviewSummary reviewCount={reviewCount} starRating={this.props.selectedLocation.starRating} ribbitRating={this.props.selectedLocation.letterRating} />
              {swapButton}
            </div>
            <div style={{ width: '49%', float: 'right', maxHeight: 400, overflow: 'scroll' }}>
              <a onClick={this.props.onClose} style={{ 'height': 0, 'float': 'right', 'cursor': 'pointer' }}>&times;</a>
              { this.state.openReview ? (<QuestionForm onClose={this.onReviewsClose.bind(this)} />) : <ReviewsList selectedLocation={this.props.selectedLocation} /> }
            </div>
          </Paper>
        </Modal>
      )
    } else {
      return ''
    }
  }
}

module.exports = withRouter(connect(mapStateToProps)(LocationDetailsContainer))
