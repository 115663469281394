import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import { connect } from 'react-redux'
import { search } from '../redux/actions'
import ClearIcon from '@material-ui/icons/Clear'
import SearchIcon from '@material-ui/icons/Search'
import { withStyles } from '@material-ui/core/styles'
import InputBase from '@material-ui/core/InputBase'

const styles = theme => ({
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: 'whitesmoke'
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: 0,
      width: 'auto'
    }
  },
  searchIcon: {
    width: theme.spacing.unit * 9,
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  searchIconButton: {
    width: theme.spacing.unit * 9,
    height: '100%',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  clearIconButton: {
    width: theme.spacing.unit * 9,
    height: '100%',
    position: 'absolute',
    right: '0%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  inputRoot: {
    color: 'inherit',
    width: '100%'
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit * 10,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 10,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 200,
      '&:focus': {
        width: 400
      }
    }
  }
})

const mapStateToProps = state => {
  return {
    currentLocation: state.currentLocation,
    mapBounds: state.mapBounds
  }
}

const mapDispatchToProps = dispatch => {
  return {
    search: searchInfo => {
      dispatch(search(searchInfo))
    }
  }
}

class SearchBar extends Component {
  constructor (props) {
    super(props)
    this.state = {
      searchField: ''
    }
  }

  handleChange (event) {
    this.setState({ [event.target.id]: event.target.value })
    // this is used if we want to use autocomplete
    // if (event.target.value.length >= 3){
    // console.log(event.target.value)
    // / / dispatch action with search here for suggestions
    // }
  }

  clearSearch (event) {
    this.setState({ searchField: '' })
    let { ne, sw } = this.props.mapBounds
    let searchInfo = {
      searchTerms: '',
      location: this.props.currentLocation,
      ne: ne,
      sw: sw,
      createdLocation: this.props.currentLocation
    }
    // sentinal search clear
    if (!searchInfo.searchTerms) {
      searchInfo.searchTerms = '99999999999999999999'
    }
    this.props.search(searchInfo)
  }

  submitSearch (event) {
    console.log('search')
    let { ne, sw } = this.props.mapBounds
    let searchInfo = {
      searchTerms: this.state.searchField,
      location: this.props.currentLocation,
      ne: ne,
      sw: sw,
      createdLocation: this.props.currentLocation
    }
    console.log(searchInfo)
    this.props.search(searchInfo)
  }

  catchEnter (e) {
    if (e.key === 'Enter') {
      e.preventDefault()
      this.submitSearch()
    }
  }

  render () {
    const { classes } = this.props
    return (
      <div>
        <div className={classes.search}>
          <Button className={classes.searchIconButton} onClick={this.submitSearch.bind(this)}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
          </Button>
          <Button className={classes.clearIconButton} onClick={this.clearSearch.bind(this)} >
            <div className={classes.searchIcon}>
              <ClearIcon />
            </div>
          </Button>
          <InputBase
            name='searchField'
            id='searchField'
            value={this.state.searchField}
            onChange={this.handleChange.bind(this)}
            onKeyPress={this.catchEnter.bind(this)}
            placeholder='Search for pizza, coffee, etc'
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput
            }}
          />
        </div>
      </div>
    )
  }
}

module.exports = withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(SearchBar)))
// module.exports = withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchBar))
