import React, { Component } from 'react'
import { connect } from 'react-redux'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import CenteredPage from '../uiTools/CenteredPage'
import { withRouter } from 'react-router-dom'
import { fetchUser, notify } from '../../redux/actions'
import { withStyles } from '@material-ui/core/styles'
import axios from 'axios'

const mapStateToProps = state => {
  return { user: state.user }
}

const styles = theme => ({
  paper: {
    padding: '16px',
    width: '300px'
  }

})

const mapDispatchToProps = dispatch => {
  return {
    fetchUser: () => { dispatch(fetchUser()) },
    notify: (text) => { dispatch(notify(text)) }
  }
}

class EmailChangeForm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      newEmail: '',
      newEmailConf: ''
    }
  }
  handleChange (event) {
    this.setState({ [event.target.id]: event.target.value })
  }

  async handleSubmit () {
    try {
      console.log(this.state)
      const response = await axios.post('/auth/changeEmail', this.state)
      if (response.data.error) {
        this.props.notify(response.data.error)
      } else {
        this.props.notify('Email updated')
        this.props.fetchUser()
        this.props.history.push('/home')
      }
    } catch (err) {
      this.props.notify('Email change failed')
      console.log(err)
    }
  }

  async catchEnter (e) {
    if (e.key === 'Enter') {
      e.preventDefault()
      await this.handleSubmit()
    }
  }

  goBack () {
    this.props.history.push('/profile')
  }

  render () {
    const { classes } = this.props
    return (
      <div>
        <CenteredPage>
          <Paper className={classes.paper}>
            <TextField
              name='newEmail'
              label='new email'
              id='newEmail'
              value={this.state.newEmail}
              onChange={this.handleChange.bind(this)}
              onKeyPress={this.catchEnter.bind(this)}
            /><br />
            <TextField
              name='newEmailConf'
              label='email confirmation'
              id='newEmailConf'
              value={this.state.newEmailConf}
              onChange={this.handleChange.bind(this)}
              onKeyPress={this.catchEnter.bind(this)}
            /><br />
            <Button variant='contained' color='primary' onClick={this.handleSubmit.bind(this)}>
              Update Email
            </Button>
            <Button variant='contained' color='secondary' onClick={this.goBack.bind(this)}>
              Go Back
            </Button>
          </Paper>
        </CenteredPage>
      </div>
    )
  }
}

module.exports = withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(EmailChangeForm)))
