import React, { Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { connect } from 'react-redux'
import { addQuestion } from '../../redux/actions'

const mapStateToProps = state => {
  return {
    questions: state.questions
  }
}

const mapDispatchToProps = dispatch => {
  return {
    addQuestion: (data) => { dispatch(addQuestion(data)) }
  }
}

class QuestionAddForm extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      name: '',
      text: '',
      description: '',
      yesValue: 0,
      noValue: 0
    }
  }

  handleChange (event) {
    this.setState({ [event.target.id]: event.target.value })
  }
  handleSubmit (event) {
    this.props.addQuestion(this.state)
  }

  catchEnter (e) {
    if (e.key === 'Enter') {
      e.preventDefault()
      this.handleSubmit()
    }
  }

  render () {
    return (
      <Fragment>
        <TextField
          id='name'
          label='Name'
          defaultValue=' '
          onChange={this.handleChange.bind(this)}
          onKeyPress={this.catchEnter.bind(this)}
        />
        <TextField
          id='text'
          label='Text'
          defaultValue=' '
          onChange={this.handleChange.bind(this)}
          onKeyPress={this.catchEnter.bind(this)}
        />
        <TextField
          id='description'
          label='Description'
          defaultValue=' '
          onChange={this.handleChange.bind(this)}
          onKeyPress={this.catchEnter.bind(this)}
        />
        <TextField
          id='yesValue'
          label='Yes Value'
          type='number'
          value={this.state.yesValue}
          onChange={this.handleChange.bind(this)}
          onKeyPress={this.catchEnter.bind(this)}
        />
        <TextField
          id='noValue'
          label='No Value'
          type='number'
          value={this.state.noValue}
          onChange={this.handleChange.bind(this)}
          onKeyPress={this.catchEnter.bind(this)}
        />
        <Button
          color='primary'
          onClick={this.handleSubmit.bind(this)}
        >
          Add Question
        </Button>
      </Fragment>
    )
  }
}

module.exports = withRouter(connect(mapStateToProps, mapDispatchToProps)(QuestionAddForm))
