
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Rating from 'react-rating'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import LocationDetailsContainer from './LocationDetailsContainer'
import { selectLocation, uploadReview, clearSelectedLocation, hoverLocation, clearHoveredLocation } from '../../redux/actions'
import attr from '../../../../images/foursquare.svg'

const mapStateToProps = state => {
  return {
    locations: state.locations,
    hoveredLocation: state.hoveredLocation,
    selectedLocation: state.selectedLocation
  }
}

const mapDispatchToProps = dispatch => {
  return {
    selectLocation: (location) => { dispatch(selectLocation(location)) },
    clearSelectedLocation: (location) => { dispatch(clearSelectedLocation(location)) },
    hoverLocation: (location) => { dispatch(hoverLocation(location)) },
    clearHoveredLocation: (location) => { dispatch(clearHoveredLocation(location)) },
    uploadReview: (review) => { dispatch(uploadReview(review)) }
  }
}

class LocationCardConnect extends Component {
  constructor (props) {
    super(props)
    this.state = {
      selectedLocation: null
    }
  }

  // update selected location by clicking on card
  handleClick (e) {
    this.props.selectLocation(this.props.location)
    this.setState({ selectedLocation: this.props.location })
  }

  handleMouseover (e) {
    if (this.props.hoveredLocation === null) {
      this.props.hoverLocation(this.props.location._id)
    }
    if (this.props.hoveredLocation && this.props.location._id !== this.props.hoveredLocation) {
      this.props.hoverLocation(this.props.location._id)
    }
  }

  handleLocationClose () {
    this.props.clearSelectedLocation()
    this.setState({ selectedLocation: null })
  }

  colorForLocation (letter) {
    let greenList = ['A']
    let orangeList = ['B', 'C']
    let redList = ['D', 'F']

    if (greenList.includes(letter)) {
      return 'darkGreen'
    } else if (orangeList.includes(letter)) {
      return 'orange'
    } else if (redList.includes(letter)) {
      return 'darkRed'
    } else {
      return 'gray'
    }
  }

  render () {
    let style = { flex: '0 0 auto', minWidth: 300, margin: 10 }
    // let hoverStyle = { flex: '0 0 auto', minWidth: 300, margin: 10, borderStyle: 'solid', borderWidth: 5 }
    // let hovered = this.props.location && this.props.hoveredLocation && this.props.location._id == this.props.hoveredLocation._id
    // not using the below style. instead relying on marker tooltips
    // style={hovered ? hoverStyle : style}
    let reviewText = 'Reviews'
    if (this.props.location.reviews.length === 1) {
      reviewText = 'Review'
    }
    return (
      <React.Fragment>
        {this.state.selectedLocation && <LocationDetailsContainer onClose={this.handleLocationClose.bind(this)} />}
        <Card
          style={style}
          onClick={this.handleClick.bind(this)}
          onMouseOver={this.handleMouseover.bind(this)}
        >
          <CardContent>
            <Typography variant='title'>
              {this.props.location.name}
              {<b style={{ float: 'right', color: 'white', borderRadius: '50%', padding: '7px', minWidth: 25, minHeight: 25, textAlign: 'center', backgroundColor: this.colorForLocation(this.props.location.letterRating) }}>{this.props.location.letterRating}</b>}
            </Typography>
            <Typography variant='subheading'>{this.props.location.location.address}</Typography>
            <Rating
              emptySymbol={<Typography variant='headline'>☆</Typography>}
              fullSymbol={<Typography variant='headline' style={{ color: 'gold', outline: 'none' }}>★</Typography>}
              initialRating={this.props.location.starRating}
              readonly />
            <Typography variant='subheading'>
              {this.props.location.reviews.length} {reviewText}
            </Typography>
          </CardContent>
        </Card>
      </React.Fragment>
    )
  }
}

const LocationCard = connect(mapStateToProps, mapDispatchToProps)(LocationCardConnect)

class LocationCards extends Component {
  render () {
    let locations = ''
    if (this.props.locations) {
      locations = this.props.locations
      locations = locations.map((location) => {
        return <LocationCard
          key={location._id}
          location={location} />
      })
    }
    return (
      <div style={{ position: 'fixed', zIndex: 1000, width: '97%', bottom: 0 }}>
        <img src={attr} style={{ width: '15vw', marginLeft: 10 }} />
        <div style={{ display: 'flex', flexWrap: 'nowrap', overflowX: 'scroll' }}>
          {locations}
        </div>
      </div>
    )
  }
}

module.exports = withRouter(connect(mapStateToProps)(LocationCards))
