import React, { Component, Fragment } from 'react'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { withRouter } from 'react-router-dom'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import axios from 'axios'
import { notify } from './../../redux/actions'
import { connect } from 'react-redux'

const mapDispatchToProps = dispatch => {
  return {
    notify: (text) => { dispatch(notify(text)) }
  }
}
class ReportFormSimple extends Component {
  constructor (props) {
    super(props)
    this.state = {
      open: false,
      text: ''
    }
  }

  handleOpen () {
    this.setState({ open: true })
    console.log(this.props)
  }

  handleClose () {
    this.setState({ open: false })
  }

  handleChange (event) {
    this.setState({ [event.target.id]: event.target.value })
  }

  async handleReport () {
    const reviewId = this.props.reviewId
    const text = this.state.text
    try {
      await axios.post('/locations/reportReview', { reviewId, text })
      this.props.notify('Review Reported!')
    } catch (err) {
      this.props.notify('Review report failed')
    }
    this.handleClose()
  }

  render () {
    return (
      <Fragment>
        <Button
          onClick={this.handleOpen.bind(this)}
        >
          Report this Review
        </Button>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose.bind(this)}
        >
          <DialogTitle>Report</DialogTitle>
          <DialogContent>
            <DialogContentText>
              What is your reason for reporting this content?
            </DialogContentText>
            <TextField
              autoFocus
              id='text'
              label='Report Reason'
              type='text'
              onChange={this.handleChange.bind(this)}
            />
          </DialogContent>
          <DialogActions>
            <Button
              variant='contained'
              color='primary'
              onClick={this.handleReport.bind(this)}
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    )
  }
}
const ReportForm = connect(null, mapDispatchToProps)(ReportFormSimple)
class ReviewsList extends Component {
  render () {
    console.log(this.props.selectedLocation)
    let reviews = this.props.selectedLocation.reviews
    console.log(reviews)
    let reviewsDisplay = (
      <Card>
        <Typography>Be the first to review!</Typography>
      </Card>
    )
    if (this.props.userReview) {
      reviewsDisplay = (
        <Card>
          <Typography>You are the only reviewer!</Typography>
        </Card>
      )
    }
    if (reviews.length > 0) {
      let i = 0
      // TODO: handle the user's own review in a special way
      reviewsDisplay = reviews.filter(review => review.text !== '').map((review) => (
        <Card style={{ margin: 10, padding: 10 }} key={i++}>
          <CardContent>
            <Typography><b>{review.user.username}</b></Typography>
            <Typography>Stars: {review.starRating}</Typography>
            <Typography>Review: {review.text}</Typography>
          </CardContent>
          <CardActions>
            <ReportForm reviewId={review._id} />
          </CardActions>
        </Card>
      ))
    }
    return reviewsDisplay
  }
}

module.exports = withRouter(ReviewsList)
