import React, { Component } from 'react'
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography'
// import Button from '@material-ui/core/Button'
import { withRouter } from 'react-router-dom'
import { fetchUser, clearUser, notify } from '../../redux/actions'
import axios from 'axios'
const mapStateToProps = state => {
  return { user: state.user }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchUser: () => { dispatch(fetchUser()) },
    clearUser: () => { dispatch(clearUser()) },
    notify: (text) => { dispatch(notify(text)) }
  }
}

class EmailVerify extends Component {
  componentDidMount () {
    this.props.fetchUser()
    if (!this.props.user) {
      this.props.history.push('/login')
    }
  }

  async verifyEmail () {
    try {
      await axios.get('/auth/sendVerificationEmail')
      this.props.notify('Verification email sent')
    } catch (err) {
      this.props.notify('Verification email failed to send')
      console.log(err)
    }
  }

  render () {
    let output = (
      <Typography component='div' color='textSecondary'>
        Email is verified
      </Typography>
    )
    if (!this.props.user.emailVerified) {
      output = (
        <span>
          <Typography component='div' color='textSecondary'>
            Email is not verified
          </Typography>
          {/*
          <Button variant='contained' color='secondary' onClick={this.verifyEmail.bind(this)}>
            Verify Email Now
          </Button>
          */}
        </span>
      )
    }
    return output
  }
}

module.exports = withRouter(connect(mapStateToProps, mapDispatchToProps)(EmailVerify))
