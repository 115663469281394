// src/frontend/redux/store.js

import { createStore, applyMiddleware } from 'redux'
import { rootReducer } from './reducers'
import createSagaMiddleware from 'redux-saga'
import { composeWithDevTools } from 'redux-devtools-extension'

// the actual initial state is defined in the reducers
export const initialState = {}

// const reduxDevTools = window.devToolsExtension && window.devToolsExtension()
export const sagaMiddleware = createSagaMiddleware()
const composeEnhancers = composeWithDevTools({})

let middleware = applyMiddleware(sagaMiddleware)
if (process.env.DEV) {
  middleware = composeEnhancers(applyMiddleware(sagaMiddleware))
}

export const store = createStore(
  rootReducer,
  initialState,
  middleware
)
