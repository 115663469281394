import React, { Component } from 'react'
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import { withRouter } from 'react-router-dom'
import { fetchUser, clearUser, notify } from '../../redux/actions'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import PrivateToggle from './PrivateToggle'
import LogoutButton from './LogoutButton'
import EmailVerify from './EmailVerify'
import { withStyles } from '@material-ui/core/styles'
import Help from '@material-ui/icons/Help'
import Tooltip from '@material-ui/core/Tooltip'

const mapStateToProps = state => {
  return { user: state.user }
}

const styles = theme => ({
  paper: {
    padding: '16px',
    width: '300px'
  }

})

const mapDispatchToProps = dispatch => {
  return {
    fetchUser: () => { dispatch(fetchUser()) },
    clearUser: () => { dispatch(clearUser()) },
    notify: (text) => { dispatch(notify(text)) }
  }
}

class Profile extends Component {
  componentDidMount () {
    this.props.fetchUser()
    if (!this.props.user) {
      this.props.history.push('/')
    }
  }

  async refreshUser () {
    this.props.fetchUser()
    this.props.notify('User info updated')
  }

  async emailChange () {
    this.props.history.push('/changeEmail')
  }

  async passwordChange () {
    this.props.history.push('/changePassword')
  }

  goBack () {
    this.props.history.push('/home')
  }

  render () {
    const { classes } = this.props
    let page = ''
    const user = this.props.user
    if (user) {
      const { username, email, emailVerified } = user
      console.log(user)
      console.log(emailVerified)
      page = (
        <div>
          <Paper className={classes.paper}>
            <List className={classes.root}>
              <ListItem>
                <ListItemText primary='Username' />
                <ListItemSecondaryAction>
                  <Typography component='div' color='textSecondary'>
                    {username}
                  </Typography>
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem>
                <ListItemText primary='Email' />
                <ListItemSecondaryAction>
                  <Typography component='div' color='textSecondary'>
                    {email}
                  </Typography>
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem>
                <Tooltip title='Email must be verified to send password resets.'>
                  <Help />
                </Tooltip>
                <ListItemText primary='Email Verified?' />
                <ListItemSecondaryAction>
                  <EmailVerify />
                </ListItemSecondaryAction>
              </ListItem>
              <PrivateToggle />
              <ListItem>
                <Button variant='contained' color='secondary' onClick={this.emailChange.bind(this)}>
                  Change Email
                </Button>
              </ListItem>
              <ListItem>
                <Button variant='contained' color='secondary' onClick={this.passwordChange.bind(this)}>
                  Change Password
                </Button>
              </ListItem>
              {/* <EmailChangeForm /> <br />
              <PasswordChangeForm /> <br /> */}
              <ListItem>
                <Button variant='contained' color='secondary' onClick={this.refreshUser.bind(this)}>
                  Refresh
                </Button>
                <Button variant='contained' color='secondary' onClick={this.goBack.bind(this)}>
                  Done
                </Button>
                <ListItemSecondaryAction>
                  <LogoutButton />
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          </Paper>
        </div>
      )
    }
    return page
  }
}

module.exports = withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Profile)))
