// src/frontend/index.js
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import 'babel-polyfill'
import App from './components/App.jsx'
import { store, sagaMiddleware } from './redux/store.js'
// require("babel-core/register");
// require("babel-polyfill");
import { LOAD_ARTICLES, GET_CURRENT_LOCATION } from './redux/actions.js'
import { rootSaga } from './redux/sagas'
window.store = store

sagaMiddleware.run(rootSaga)

//  TODO: use export from actions instead of defining it here
const action = type => store.dispatch({ type })

// action('HELLO_ASYNC')
// TODO: move to newsfeed component
action(LOAD_ARTICLES)
action(GET_CURRENT_LOCATION)

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('main')
)
