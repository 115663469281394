import React, { Component } from 'react'
import { connect } from 'react-redux'
import CardActions from '@material-ui/core/CardActions'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { withRouter } from 'react-router-dom'
import { selectLocation, notify } from '../../redux/actions'
import Rating from 'react-rating'
import axios from 'axios'

const mapStateToProps = state => {
  return {
    selectedLocation: state.selectedLocation,
    currentLocation: state.currentLocation
  }
}

const mapDispatchToProps = dispatch => {
  return {
    selectLocation: (location) => { dispatch(selectLocation(location)) },
    notify: (text) => { dispatch(notify(text)) }
  }
}

class ReviewForm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      starRating: 0,
      text: '',
      existingReview: {}
    }
  }
  componentDidMount () {
    if (this.props.selectedLocation.userReview) {
      const userReview = this.props.selectedLocation.userReview
      this.setState({
        existingReview: userReview,
        text: userReview.text,
        starRating: userReview.starRating
      })
    }
  }
  handleStarsChange (value) {
    this.setState({ starRating: value })
  }

  handleChange (event) {
    this.setState({ [event.target.id]: event.target.value })
  }
  async handleSubmit (event) {
    const reviewData = {
      location: this.props.selectedLocation._id,
      starRating: this.state.starRating,
      text: this.state.text,
      createdLocation: this.props.currentLocation
    }
    try {
      console.log('submitting')
      console.log(reviewData)
      let result = await axios.post('/locations/uploadReview', reviewData)
      result = result.data
      if (result.error) {
        if (this.state.existingReview) {
          this.props.notify('Review update failed')
        } else {
          this.props.notify('Review upload failed')
        }
      } else {
        if (this.state.existingReview) {
          this.props.notify('Review updated!')
        } else {
          this.props.notify('Review uploaded!')
        }
        this.props.selectLocation(this.props.selectedLocation)
        this.props.onClose()
      }
    } catch (err) {
      console.log(err)
      if (this.state.existingReview) {
        this.props.notify('Review update failed')
      } else {
        this.props.notify('Review upload failed')
      }
    }
  }
  handleDelete () {
    try {
      const response = axios.post('locations/deleteReview', {
        review: this.state.existingReview._id
      })
      if (response.error) {
        this.props.notify('Review failed to delete')
      } else {
        this.props.notify('Review Deleted!')
        this.setState({
          starRating: 0,
          text: '',
          existingReview: null
        })
      }
    } catch (err) {
      console.log(err)
      this.props.notify('Review failed to delete')
    }
  }

  render () {
    let formTitle = 'Leave a Review'
    let deleteButton = ''
    if (this.state.existingReview) {
      formTitle = 'Update your Review'
      deleteButton = (
        <Button
          variant='contained'
          color='primary'
          onClick={this.handleDelete.bind(this)}
        >
          Delete
        </Button>
      )
    }
    return (
      <React.Fragment>
        <br />
        <Typography variant='headline'>{formTitle}</Typography>
        <form noValidate autoComplete='off'>
          <CardActions>
            <Rating
              initialRating={this.state.starRating}
              emptySymbol={<Typography variant='headline'>☆</Typography>}
              fullSymbol={<Typography variant='headline' style={{ color: 'gold', outline: 'none' }}>★</Typography>}
              value={this.state.starRating}
              onChange={this.handleStarsChange.bind(this)} />
          </CardActions>
          <CardActions>
            <TextField id='text'
              value={this.state.text}
              label='Review Text...'
              multiline
              onChange={this.handleChange.bind(this)}
            />
          </CardActions>
          <CardActions>
            <Button
              variant='contained'
              color='primary'
              onClick={this.handleSubmit.bind(this)}>
            Submit
            </Button>
            {deleteButton}
          </CardActions>
        </form>
      </React.Fragment>
    )
  }
}

module.exports = withRouter(connect(mapStateToProps, mapDispatchToProps)(ReviewForm))
