import {
  LOAD_ARTICLES_SUCCESS,
  LOAD_ARTICLES_FAILURE,
  ADD_ARTICLE_FAILURE,
  ADD_ARTICLE_SUCCESS,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILURE,
  CLEAR_USER,
  SET_PHOTO_SUCCESS,
  SET_PHOTO_FAILURE,
  CLEAR_PHOTO,
  GET_CURRENT_LOCATION_SUCCESS,
  GET_CURRENT_LOCATION_FAILURE,
  SEARCH_SUCCESS,
  SEARCH_FAILURE,
  TOGGLE_PRIVATE_SUCCESS,
  TOGGLE_PRIVATE_FAILURE,
  SHARE_MAP_BOUNDS,
  SELECT_LOCATION_SUCCESS,
  CLEAR_SELECTED_LOCATION,
  HOVER_LOCATION,
  CLEAR_HOVERED_LOCATION,
  ADD_QUESTION_SUCCESS,
  GET_QUESTIONS_SUCCESS,
  QUESTION_ARCHIVED,
  ERROR,
  QUESTION_UNARCHIVED,
  QUESTION_EDITED,
  NOTIFY,
  REMOVE_LATEST_NOTIFICATION,
  CLEAR_NOTIFICATIONS
} from './actions'
import { combineReducers } from 'redux'
import { defaultLocation } from '../components/Map'

const articleReducer = (state = [], { type, payload }) => {
  switch (type) {
    case ADD_ARTICLE_SUCCESS: // add article with post then return state with new article
      return [...state, payload]
    case ADD_ARTICLE_FAILURE: // add article with post then return state with new article
      return state
    case LOAD_ARTICLES_SUCCESS:
      return [...state, ...payload]
    case LOAD_ARTICLES_FAILURE:
      return state
    default:
      return state
  }
}

const userReducer = (state = null, { type, payload }) => {
  switch (type) {
    // TODO: check if user is different before adding it
    case FETCH_USER_SUCCESS:
      return payload
    case FETCH_USER_FAILURE:
      return state
    case TOGGLE_PRIVATE_SUCCESS:
      return payload
    case TOGGLE_PRIVATE_FAILURE:
      return state
    case CLEAR_USER:
      return null
    default:
      return state
  }
}

const photoReducer = (state = [], { type, payload }) => {
  switch (type) {
    case SET_PHOTO_SUCCESS: // set a photo to the preview  window
      return [...state, payload]
    case SET_PHOTO_FAILURE:
      return state
    case CLEAR_PHOTO: // clear the current photo
      return [...state, '']
    default:
      return state
  }
}

// used to get current location and share it throughout the app
const currentLocationReducer = (
  state = defaultLocation,
  { type, payload }
) => {
  switch (type) {
    case GET_CURRENT_LOCATION_SUCCESS:
      return payload
    case GET_CURRENT_LOCATION_FAILURE:
      return state
    default:
      return state
  }
}

// used to store the locations from the foursquare searches
const locationsReducer = (state = [], { type, payload }) => {
  switch (type) {
    case SEARCH_SUCCESS:
      return payload
    case SEARCH_FAILURE:
      return state
    default:
      return state
  }
}

// used to share the map boundaries (ne and sw) between components
const mapBoundsReducer = (state = null, { type, payload }) => {
  switch (type) {
    case SHARE_MAP_BOUNDS:
      return payload
    default:
      return state
  }
}

// used to share the currently selected location between components
const selectedLocationReducer = (state = null, { type, payload }) => {
  switch (type) {
    case SELECT_LOCATION_SUCCESS:
      return payload
    case CLEAR_SELECTED_LOCATION:
      return null
    default:
      return state
  }
}

const hoveredLocationReducer = (state = null, { type, payload }) => {
  switch (type) {
    case HOVER_LOCATION:
      return payload
    case CLEAR_HOVERED_LOCATION:
      return null
    default:
      return state
  }
}

const questionsReducer = (state = [], { type, payload }) => {
  switch (type) {
    case ADD_QUESTION_SUCCESS:
      return [...state, payload]
    case GET_QUESTIONS_SUCCESS:
      return payload
    // payload is the index of the removed item, not a question or questions
    case QUESTION_ARCHIVED:
      return [...state.slice(0, payload), ...state.slice(payload + 1)]
    case QUESTION_UNARCHIVED:
      return [...state, payload]
    // payload = {data: editedQuestion, index: number}
    case QUESTION_EDITED:
      return [...state.slice(0, payload.index), payload.data, ...state.slice(payload.index + 1)]
    default:
      return state
  }
}

const errorReducer = (state = [], { type, payload }) => {
  switch (type) {
    case ERROR:
      return [...state, payload]
    default:
      return state
  }
}

const notificationsReducer = (state = [], { type, payload }) => {
  switch (type) {
    case NOTIFY:
      return [...state, payload]
    case REMOVE_LATEST_NOTIFICATION:
      return state.slice(0, state.length - 1)
    case CLEAR_NOTIFICATIONS:
      return []
    default:
      return state
  }
}

export const rootReducer = combineReducers({
  articles: articleReducer,
  user: userReducer,
  photo: photoReducer,
  currentLocation: currentLocationReducer,
  locations: locationsReducer,
  mapBounds: mapBoundsReducer,
  selectedLocation: selectedLocationReducer,
  hoveredLocation: hoveredLocationReducer,
  questions: questionsReducer,
  errors: errorReducer,
  notifications: notificationsReducer
})
