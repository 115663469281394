import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { clearUser } from '../../redux/actions'
import { Login } from '../Auth'
import Grid from '@material-ui/core/Grid'
// import Paper from '@material-ui/core/Paper'
// import Fab from '@material-ui/core/Fab'
// import ArrowDownward from '@material-ui/icons/ArrowDownward'
// import ArrowUpward from '@material-ui/icons/ArrowUpward'
import './Landing.css'
import './logo.png'

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: '24pt',
    textAlign: 'center',
    color: theme.palette.text.secondary
  },
  button: {
    margin: '24pt'
  }
})

const mapStateToProps = state => {
  return { user: state.user }
}

const mapDispatchToProps = dispatch => {
  return {
    clearUser: () => { dispatch(clearUser()) }
  }
}

class Landing extends Component {
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  render () {
    const { classes } = this.props
    return (
      <div className={classes.root}>
        <div id='signup'>
          <Grid container
            spacing={24}
            alignItems='center'
            justify='space-evenly'
            style={{
              height: '100vh',
              width: '100vw',
              padding: '24pt',
              position: 'absolute'
            }}>
            <Grid item xs={12} >
              <div style={{ textAlign: 'center' }} />
            </Grid>
            <Grid item>
              <div style={{ textAlign: 'center' }}>
                <img id='logo' src={require('./logo.png')} alt='' />
              </div>
            </Grid>
            <Grid item>
              <Login />
            </Grid>
            {/* Continue to more information */}
            <Grid item xs={12}>
              {/* <div style={{ textAlign: 'center' }}>
                <Fab color='secondary' aria-label='down' className={classes.fab} href='#learnMore'>
                  <ArrowDownward />
                </Fab>
              </div> */}
            </Grid>
          </Grid>
        </div>
        {/* Uncomment for autoscroll to more information */}
        {/* <div id='learnMore' >
          <Grid container
            spacing={24}
            alignItems='center'
            style={{
              height: '100vh',
              width: '100vw',
              padding: '24pt',
              position: 'absolute'
            }}>
            <Grid item xs={12}>
              <div style={{ textAlign: 'center' }}>
                <Fab color='secondary' aria-label='up' className={classes.fab} href='#signup'>
                  <ArrowUpward />
                </Fab>
              </div>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Paper className={classes.paper}>
                <h1>Title</h1>
                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus ultrices leo quis sem tincidunt, pretium ornare mi suscipit. Mauris in felis sed ipsum gravida tempor. Cras a orci tincidunt, consequat nisi id, posuere arcu.</p>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Paper className={classes.paper}>
                <h1>Title</h1>
                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus ultrices leo quis sem tincidunt, pretium ornare mi suscipit. Mauris in felis sed ipsum gravida tempor. Cras a orci tincidunt, consequat nisi id, posuere arcu. </p>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Paper className={classes.paper}>
                <h1>Title</h1>
                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus ultrices leo quis sem tincidunt, pretium ornare mi suscipit. Mauris in felis sed ipsum gravida tempor. Cras a orci tincidunt, consequat nisi id, posuere arcu. </p>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <div style={{ textAlign: 'center' }}>
                <Fab color='secondary' aria-label='down' className={classes.fab} href='#ourMission'>
                  <ArrowDownward />
                </Fab>
              </div>
            </Grid>
          </Grid>
        </div>
        <div id='ourMission'>
          <Grid container
            spacing={24}
            alignItems='center'
            style={{
              height: '100vh',
              width: '100vw',
              padding: '24pt',
              position: 'absolute'
            }}>
            <Grid item xs={12}>
              <div style={{ textAlign: 'center' }}>
                <Fab color='secondary' aria-label='up' className={classes.fab} href='#signup'>
                  <ArrowUpward />
                </Fab>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper className={classes.paper}>
                <h1>Title</h1>
                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus ultrices leo quis sem tincidunt, pretium ornare mi suscipit. Mauris in felis sed ipsum gravida tempor. Cras a orci tincidunt, consequat nisi id, posuere arcu. </p>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper className={classes.paper}>
                <h1>Title</h1>
                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus ultrices leo quis sem tincidunt, pretium ornare mi suscipit. Mauris in felis sed ipsum gravida tempor. Cras a orci tincidunt, consequat nisi id, posuere arcu. </p>
              </Paper>
            </Grid>
            <Grid item xs={12} >
              <div style={{ textAlign: 'center' }} />
            </Grid>
          </Grid>
        </div> */}
      </div>
    )
  }
}

module.exports = withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Landing)))
