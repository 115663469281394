
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Switch from '@material-ui/core/Switch'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import { withRouter } from 'react-router-dom'
import { fetchUser, notify } from '../../redux/actions'
import Help from '@material-ui/icons/Help'
import Tooltip from '@material-ui/core/Tooltip'
// import Typography from '@material-ui/core/Typography'
import axios from 'axios'
const mapStateToProps = state => {
  return { user: state.user }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchUser: () => { dispatch(fetchUser()) },
    notify: (text) => { dispatch(notify(text)) }
  }
}

class PrivateToggle extends Component {
  async handleChange (event) {
    let url = '/user/togglePrivate'
    await axios.get(url, { params: { value: event.target.checked } })
    await this.props.fetchUser()
    let textStatus = ''
    if (this.props.user.privateToggle) {
      textStatus = 'public'
    } else {
      textStatus = 'private'
    }
    await this.props.notify('Profile is now ' + textStatus)
  }

  render () {
    let text = 'User profile is '
    if (this.props.user.privateToggle) {
      text += 'Private'
    } else {
      text += 'Public'
    }
    return (
      <div>
        <ListItem>
          <Tooltip title='Other users can not see the username of private accounts on reviews.'>
            <Help />
          </Tooltip>
          <ListItemText primary={text} />
          <ListItemSecondaryAction>
            <Switch
              checked={this.props.user.privateToggle}
              onChange={this.handleChange.bind(this)}
              color='primary'
            />
          </ListItemSecondaryAction>
        </ListItem>
      </div>
    )
  }
}

module.exports = withRouter(connect(mapStateToProps, mapDispatchToProps)(PrivateToggle))
