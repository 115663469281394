import React, { Component } from 'react'
import Typography from '@material-ui/core/Typography'
import Rating from 'react-rating'

class ReviewSummary extends Component {
  render () {
    let reviewText = 'Reviews'
    if (this.props.reviewCount === 1) {
      reviewText = 'Review'
    }
    return (
      <div style={{ padding: 15 }}>
        <Typography variant='subheading'>Ribbit Rating: {this.props.ribbitRating} </Typography>
        <Typography variant='subheading'>Food Quality: {this.props.reviewCount} {reviewText}</Typography>
        <Rating
          emptySymbol={<Typography variant='headline'>☆</Typography>}
          fullSymbol={<Typography variant='headline' style={{ color: 'gold', outline: 'none' }}>★</Typography>}
          initialRating={this.props.starRating}
          readonly />
      </div>
    )
  }
}

module.exports = ReviewSummary
