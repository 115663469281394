// src/frontend/redux/actions.js
import { store } from './store'

// used to dispatch sagas
// TODO: once the one other instance of this is moved to a component, is it
// really needed?
export const action = type => store.dispatch({ type })

// Add article
export const ADD_ARTICLE = 'ADD_ARTICLE'
export const addArticle = article => ({
  type: ADD_ARTICLE,
  payload: article
})

export const ADD_ARTICLE_SUCCESS = 'ADD_ARTICLE_SUCCESS'
export const addArticleSuccess = article => ({
  type: ADD_ARTICLE_SUCCESS,
  payload: article
})

// Load articles
// TODO: do i need the payload for this?
export const LOAD_ARTICLES = 'LOAD_ARTICLES'
export const loadArticles = articles => ({
  type: LOAD_ARTICLES,
  payload: articles
})

export const LOAD_ARTICLES_SUCCESS = 'LOAD_ARTICLES_SUCCESS'
export const loadArticlesSuccess = articles => ({
  type: LOAD_ARTICLES_SUCCESS,
  payload: articles
})

export const CLEAR_USER = 'CLEAR_USER'
export const clearUser = () => ({
  type: CLEAR_USER
})

export const FETCH_USER = 'FETCH_USER'
export const fetchUser = () => ({
  type: FETCH_USER
})

export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS'
export const fetchUserSuccess = user => ({
  type: FETCH_USER_SUCCESS,
  payload: user
})

export const SET_PHOTO = 'SET_PHOTO'
export const setPhoto = (photo) => ({
  type: SET_PHOTO,
  payload: photo
})

export const SET_PHOTO_SUCCESS = 'SET_PHOTO_SUCCESS'
export const setPhotoSuccess = (photoId) => ({
  type: SET_PHOTO_SUCCESS,
  payload: photoId
})

export const CLEAR_PHOTO = 'CLEAR_PHOTO'
export const clearPhoto = () => ({
  type: CLEAR_PHOTO
})

export const GET_CURRENT_LOCATION = 'GET_CURRENT_LOCATION'
export const getCurrentLocation = () => ({
  type: GET_CURRENT_LOCATION
})

export const GET_CURRENT_LOCATION_SUCCESS = 'GET_CURRENT_LOCATION_SUCCESS'
export const getCurrentLocationSucess = (location) => ({
  type: GET_CURRENT_LOCATION_SUCCESS,
  payload: location
})

export const SEARCH = 'SEARCH'
export const search = (searchTerms) => ({
  type: SEARCH,
  payload: searchTerms
})

export const SEARCH_SUCCESS = 'SEARCH_SUCCESS'
export const searchSuccess = (results) => ({
  type: SEARCH_SUCCESS,
  payload: results
})

export const SHARE_MAP_BOUNDS = 'SHARE_MAP_BOUNDS'
export const shareMapBounds = (bounds) => ({
  type: SHARE_MAP_BOUNDS,
  payload: bounds
})

export const SELECT_LOCATION = 'SELECT_LOCATION'
export const selectLocation = (location) => ({
  type: SELECT_LOCATION,
  payload: location
})

export const HOVER_LOCATION = 'HOVER_LOCATION'
export const hoverLocation = (location) => ({
  type: HOVER_LOCATION,
  payload: location
})

export const SELECT_LOCATION_SUCCESS = 'SELECT_LOCATION_SUCCESS'
export const selectLocationSuccess = (location) => ({
  type: SELECT_LOCATION_SUCCESS,
  payload: location
})

export const HOVER_LOCATION_SUCCESS = 'HOVER_LOCATION_SUCCESS'
export const hoverLocationSuccess = (location) => ({
  type: HOVER_LOCATION_SUCCESS,
  payload: location
})

export const CLEAR_SELECTED_LOCATION = 'CLEAR_SELECTED_LOCATION'
export const clearSelectedLocation = () => ({
  type: CLEAR_SELECTED_LOCATION
})

export const CLEAR_HOVERED_LOCATION = 'CLEAR_HOVERED_LOCATION'
export const clearHoveredLocation = () => ({
  type: CLEAR_HOVERED_LOCATION
})

export const ADD_QUESTION = 'ADD_QUESTION'
export const addQuestion = (questionData) => ({
  type: ADD_QUESTION,
  payload: questionData
})

export const ADD_QUESTION_SUCCESS = 'ADD_QUESTION_SUCCESS'
export const addQuestionSuccess = (question) => ({
  type: ADD_QUESTION_SUCCESS,
  payload: question
})

export const GET_QUESTIONS = 'GET_QUESTIONS'
export const getQuestions = () => ({
  type: GET_QUESTIONS
})

export const GET_QUESTIONS_SUCCESS = 'GET_QUESTIONS_SUCCESS'
export const getQuestionsSuccess = (questions) => ({
  type: GET_QUESTIONS_SUCCESS,
  payload: questions
})

// used to both archive and unarchive questions
// as it is used with a saga
// value is {value:bool, index:#}
// index is used in case of removal
export const ARCHIVE_QUESTION = 'ARCHIVE_QUESTION'
export const archiveQuestion = (value) => ({
  type: ARCHIVE_QUESTION,
  payload: value
})

// index is of item removed
export const QUESTION_ARCHIVED = 'QUESTION_ARCHIVED'
export const questionArchived = (index) => ({
  type: QUESTION_ARCHIVED,
  payload: index
})

export const QUESTION_UNARCHIVED = 'QUESTION_UNARCHIVED'
export const questionUnarchived = (question) => ({
  type: QUESTION_UNARCHIVED,
  payload: question
})

export const EDIT_QUESTION = 'EDIT_QUESTION'
export const editQuestion = (changes) => ({
  type: EDIT_QUESTION,
  payload: changes
})

export const QUESTION_EDITED = 'QUESTION_EDITED'
export const questionEdited = (question) => ({
  type: QUESTION_EDITED,
  payload: question
})

export const ERROR = 'ERROR'
export const error = (errorData) => ({
  type: ERROR,
  payload: errorData
})

export const NOTIFY = 'NOTIFY'
export const notify = (data) => ({
  type: NOTIFY,
  payload: data
})

export const REMOVE_LATEST_NOTIFICATION = 'REMOVE_LATEST_NOTIFICATION'
export const removeLatestNotification = () => ({
  type: REMOVE_LATEST_NOTIFICATION
})

export const CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS'
export const clearNotifications = () => ({
  type: CLEAR_NOTIFICATIONS
})
