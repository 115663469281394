import React, { Component } from 'react'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import axios from 'axios'

class LocationTitle extends Component {
  constructor (props) {
    super(props)
    this.state = {
      imageUrl: null
    }
  }

  componentDidMount () {
    this.getImageForLocation()
  }

  componentWillUnmount () {
    this.setState({ imageUrl: null })
  }

  async getVenueId (locationId) {
    const venueId = await axios.get('/locations/venueId', {
      params: {
        locationId: locationId.toString()
      }
    })
    return venueId.data
  }

  async getImageForLocation () {
    let venueId = await this.getVenueId(this.props.location._id)
    let baseUrl = '/locations/picture'
    let response = await axios.get(baseUrl, {
      params: {
        locationId: venueId
      }
    })
    if (response.data.response.photos.items[0]) {
      this.setState({ imageUrl: `${response.data.response.photos.items[0].prefix}500x300${response.data.response.photos.items[0].suffix}` })
    }
  }

  render () {
    return (
      <Card>
        {this.state.imageUrl && <CardMedia
          image={this.state.imageUrl}
          title='test title for image'
          style={{ minHeight: 200 }}
        />}
        <CardContent>
          <Typography variant='headline'>{this.props.location.name}</Typography>
        </CardContent>
      </Card>
    )
  }
}

module.exports = LocationTitle
