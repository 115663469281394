
import React, { Component } from 'react'
import { connect } from 'react-redux'
import CardActions from '@material-ui/core/CardActions'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { withRouter } from 'react-router-dom'
import { selectLocation, uploadReview, notify } from '../../redux/actions'
import Paper from '@material-ui/core/Paper'
import ReviewForm from './ReviewForm'
import Help from '@material-ui/icons/Help'
import Tooltip from '@material-ui/core/Tooltip'
import axios from 'axios'
const mapStateToProps = state => {
  return {
    selectedLocation: state.selectedLocation,
    currentLocation: state.currentLocation
  }
}

const mapDispatchToProps = dispatch => {
  return {
    selectLocation: (location) => { dispatch(selectLocation(location)) },
    uploadReview: (review) => { dispatch(uploadReview(review)) },
    notify: (text) => { dispatch(notify(text)) }
  }
}

class QuestionForm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      questions: [],
      question: null,
      questionIndex: 0
    }
    this.answer = this.answer.bind(this)
  }
  async componentDidMount () {
    console.log('load questions')
    // const results = await axios.get('/questions/getQuestions')
    console.log(this.props.selectedLocation)
    const results = await axios.get('/questions/getUnansweredUserQuestions', {
      params: {
        placeId: this.props.selectedLocation._id
      }
    })
    let questions = results.data
    await this.setState({ questions: questions })
    await this.setState({ question: questions[0] })
  }
  async componentDidUpdate (prevProps) {
    if (prevProps.selectedLocation !== this.props.selectedLocation) {
      await this.componentDidMount()
    }
  }
  async reanswerQuestions () {
    try {
      let questions = await axios.get('/questions/getAllUserQuestions')
      questions = questions.data
      console.log(questions)
      await this.setState({
        questions: questions,
        question: questions[0],
        questionIndex: 0
      })
      console.log(this.state)
      this.props.notify('Questions refreshed! New responses replace prior ones.')
    } catch (err) {
      console.log(err)
      this.props.notify('Error while refreshing questions')
    }
  }
  async nextQuestion () {
    let newIndex = this.state.questionIndex + 1
    if (newIndex >= this.state.questions.length) {
      newIndex = 0
    }
    await this.setState({ questionIndex: newIndex })
    await this.setState({ question: this.state.questions[newIndex] })
  }

  async priorQuestion () {
    let newIndex = this.state.questionIndex - 1
    if (newIndex < 0) {
      newIndex = this.state.questions.length - 1
    }
    await this.setState({ questionIndex: newIndex })
    await this.setState({ question: this.state.questions[newIndex] })
  }

  async removeCurrentQuestion () {
    const questions = this.state.questions.filter(x => x !== this.state.question)
    await this.setState({ questions: questions })
    if (questions.length > 0) { // if this was not the last question
      await this.setState({ question: questions[this.state.questionIndex] })
    } else {
      await this.setState({ question: { text: '' } })
    }
  }

  async answer (response) {
    try {
      await axios.post('/questions/response', {
        response: response,
        location: this.props.selectedLocation._id,
        questionId: this.state.question._id,
        createdLocation: this.props.currentLocation
      })
      this.props.notify('Question answered')
      this.removeCurrentQuestion()
    } catch (err) {
      this.props.notify('Response failed')
      console.log(err)
    }
  }

  // TODO: refactor yes, no, and n/a to reduce duplication
  async answerYes () {
    await this.answer(1)
  }

  async answerNo () {
    await this.answer(-1)
  }

  async answerNA () {
    await this.answer(0)
  }

  render () {
    // const questionNumber = this.state.questionIndex + 1
    const totalQuestions = this.state.questions.length
    let responseButtons = ''
    let numberText = totalQuestions + ' questions left'
    if (totalQuestions === 1) {
      numberText = '1 question left'
    }
    let questionText = ''
    let questionDescription = ''
    if (this.state.question) {
      console.log('question')
      console.log(this.state.question)
      questionText = this.state.question.text
      questionDescription = this.state.question.description
    }
    let content = ''
    if (totalQuestions > 0 && this.state.question) {
      responseButtons = (
        <CardActions>
          <Button
            variant='contained'
            color='primary'
            onClick={this.priorQuestion.bind(this)}
          >
         Prior Question
          </Button>
          <Button
            variant='contained'
            color='primary'
            onClick={this.answerYes.bind(this)}
          >
         Yes
          </Button>
          <Button
            variant='contained'
            color='primary'
            onClick={this.answerNo.bind(this)}
          >
         No
          </Button>
          <Button
            variant='contained'
            color='primary'
            onClick={this.answerNA.bind(this)}
          >
         N/A
          </Button>
          <Button
            variant='contained'
            color='primary'
            onClick={this.nextQuestion.bind(this)}
          >
        Next Question
          </Button>
        </CardActions>
      )
      content = (
        <React.Fragment>
          <Typography>{numberText}</Typography>
          <Typography>
            {questionText}
            <Tooltip title={questionDescription}>
              <Help color='primary' />
            </Tooltip>
          </Typography>
          {responseButtons}
        </React.Fragment>
      )
    } else {
      content = (
        <Button
          variant='contained'
          color='primary'
          onClick={this.reanswerQuestions.bind(this)}
        >
          Reanswer Questions
        </Button>
      )
    }
    return (
      <Paper>
        {content}
        <ReviewForm onClose={this.props.onClose} />
      </Paper>
    )
  }
}

module.exports = withRouter(connect(mapStateToProps, mapDispatchToProps)(QuestionForm))
