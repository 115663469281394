// src/frontend/components/Newsfeed.js

import React, { Component } from 'react'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { addArticle, clearPhoto } from '../redux/actions'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'
import Upload from './Upload'

const mapStateToProps = state => {
  return {
    articles: state.articles,
    photoId: state.photo[state.photo.length - 1]
  }
}

const mapDispatchToProps = dispatch => {
  return {
    addArticle: article => dispatch(addArticle(article)),
    clearPhoto: () => dispatch(clearPhoto())
  }
}

function ArticlePhoto (props) {
  const style = {
    minHeight: 200
  }
  const id = props.id
  if (id) {
    return (
      <CardMedia
        image={'/files/download?id=' + id}
        title='test title for image'
        style={style}
      />
    )
  } else {
    return ''
  }
}

const cardStyle = {
  marginTop: 15,
  maxWidth: 400,
  textAlign: 'left'
}

class Newsfeed extends Component {
  render () {
    let articles = ''
    if (this.props.articles) {
      let i = 0
      articles = this.props.articles.map(a => (
        <Card style={cardStyle} key={i++}>
          <ArticlePhoto id={a.photoId} />
          <CardContent>
            <Typography gutterBottom variant='headline' component='h2'>
              {a.title}
            </Typography>
            <Typography component='div' color='textSecondary'>
                 By: {a.author}
            </Typography>
            <Typography component='p'>{a.content}</Typography>
          </CardContent>
        </Card>
      ))
    }
    return (
      <div align='center'>
        {articles}
      </div>
    )
  }
}

class ArticleForm extends Component {
  constructor (props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.state = {
      title: '',
      content: ''
    }
  }

  handleChange (event) {
    this.setState({ [event.target.id]: event.target.value })
  }

  handleSubmit (event) {
    event.preventDefault()
    const { title, content } = this.state
    const photoId = this.props.photoId
    this.props.addArticle({ title, content, photoId })
    this.setState({ title: '', content: '' })
    this.props.clearPhoto()
  }
  render () {
    return (
      <div>
        <Upload />
        <Card>
          <CardContent>
            <Typography variant='title'>Add Article</Typography>
            <TextField
              name='title'
              id='title'
              label='Title'
              value={this.state.title}
              onChange={this.handleChange}
            />
            <br />
            <TextField
              name='content'
              id='content'
              label='Content'
              value={this.state.content}
              onChange={this.handleChange}
            />
            <br />
            <Button variant='contained' onClick={this.handleSubmit}>
              Post Article
            </Button>
          </CardContent>
        </Card>
      </div>
    )
  }
}

module.exports = {
  Newsfeed: withRouter(connect(mapStateToProps)(Newsfeed)),
  ArticleForm: withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ArticleForm)
  )
}
