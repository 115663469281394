import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { fetchUser, clearUser } from './../redux/actions'
import PropTypes from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import AccountCircle from '@material-ui/icons/AccountCircle'
// import Switch from '@material-ui/core/Switch'
// import FormControlLabel from '@material-ui/core/FormControlLabel'
// import FormGroup from '@material-ui/core/FormGroup'
// import MenuItem from '@material-ui/core/MenuItem'
// import Menu from '@material-ui/core/Menu'
import SearchBar from './LocationSearchBar'

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  appBar: {
    left: '40px',
    background: 'transparent',
    position: 'absolute',
    boxShadow: 'none',
    zIndex: '100'
  },
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  }
})

const mapStateToProps = state => {
  return { user: state.user }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchUser: () => { dispatch(fetchUser()) },
    clearUser: () => { dispatch(clearUser()) }
  }
}

class Topbar extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      auth: true,
      anchorEl: null
    }
  }

  /*
  componentDidMount () {
    this.props.fetchUser()
    if (!this.props.user) {
      this.props.history.push('/login')
    }
  }
  */

  handleChange (event) {
    this.setState({ auth: event.target.checked })
  };

  handleMenu (event) {
    this.setState({ anchorEl: event.currentTarget })
  };

  handleClose () {
    this.setState({ anchorEl: null })
  };
  /*
  async logout () {
    // Implement Logout
    this.props.history.push('/')
  }
  */
  async profile () {
    this.props.history.push('/profile')
  }

  render () {
    const { classes } = this.props
    const { auth, anchorEl } = this.state
    const open = Boolean(anchorEl)

    return (
      <div className={classes.root}>

        <AppBar position='absolute' className={classes.appBar}>
          <Toolbar>
            <SearchBar />
            <Typography variant='h6' color='inherit' className={classes.grow} />
            {auth && (
              <div>
                <IconButton
                  aria-owns={open ? 'menu-appbar' : undefined}
                  aria-haspopup='true'
                  onClick={this.profile.bind(this)}
                  color='inherit'
                >
                  <AccountCircle />
                </IconButton>
                {/*
                <Menu
                  id='menu-appbar'
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  open={open}
                  onClose={this.handleClose.bind(this)}
                >
                  <MenuItem onClick={this.profile.bind(this)}>Profile</MenuItem>
                  <MenuItem onClick={this.logout.bind(this)}>Logout</MenuItem>
                </Menu>
                */}
              </div>
            )}
            {/*
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch checked={auth} onChange={this.handleChange.bind(this)} aria-label='LoginSwitch' />
                }
                // label={auth ? 'Logout' : 'Login'}
              />
            </FormGroup>
            */}
          </Toolbar>
        </AppBar>
      </div>
    )
  }
}

Topbar.propTypes = {
  classes: PropTypes.object.isRequired
}

module.exports = withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Topbar)))
