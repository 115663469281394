import React, { Component, Children } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import background from './mapBackdrop.png'

const styles = theme => ({
  bg: {
    backgroundImage: 'url(' + background + ')',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
    backgroundPosition: 'center',
    height: '100vh'
  }
})

const mapStateToProps = state => {
  return { user: state.user }
}

class CenteredPage extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
    }
  }
  render () {
    const { classes } = this.props

    return (
      <div className={classes.root}>
        <div className={classes.bg}>
          <Grid container
            spacing={24}
            alignItems='center'
            justify='center'
            style={{
              height: '100vh',
              width: '100vw',
              padding: '24pt',
              position: 'absolute'
            }}>
            <Grid item className={classes.background}>
              {this.props.children}
            </Grid>
          </Grid>
        </div>
      </div>
    )
  }
}

CenteredPage.propTypes = {
  classes: PropTypes.object.isRequired
}

module.exports = withRouter(withStyles(styles)(connect(mapStateToProps)(CenteredPage)))
