import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import { getQuestions, archiveQuestion, editQuestion, notify } from '../../redux/actions'

const mapStateToProps = state => {
  return {
    questions: state.questions
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getQuestions: () => { dispatch(getQuestions()) },
    archiveQuestion: (data) => { dispatch(archiveQuestion(data)) },
    editQuestion: (payload) => { dispatch(editQuestion(payload)) },
    notify: (text) => { dispatch(notify(text)) }
  }
}

class QuestionDisplay extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      selected: '',
      name: '',
      text: '',
      description: '',
      yesValue: 0,
      noValue: 0
    }
  }
  componentWillMount () {
    this.props.getQuestions()
  }

  handleChange (event) {
    this.setState({ [event.target.id]: event.target.value })
  }

  handleDelete (event) {
    let id = event.target.id
    let value = true
    let index = this.props.questions.findIndex((x) => x._id === id)
    this.props.archiveQuestion({ id, value, index })
  }

  async handleEdit (event) {
    let id = event.target.id
    let index = this.props.questions.findIndex((x) => x._id === id)
    let question = this.props.questions[index]
    let { name, text, description, yesValue, noValue } = question
    await this.setState({ name, text, description, yesValue, noValue, selected: id })
  }

  handleCancel () {
    this.setState({
      selected: '',
      name: '',
      text: '',
      description: '',
      yesValue: 0,
      noValue: 0
    })
    this.props.notify('Changes discarded')
  }

  handleSave (event) {
    let _id = this.state.selected
    let index = this.props.questions.findIndex((x) => x._id === _id)
    let { name, text, description, yesValue, noValue } = this.state
    let data = { _id, name, text, description, yesValue, noValue }
    let payload = { data, index }
    this.props.editQuestion(payload)
    this.handleCancel()
  }

  catchEnter (e) {
    if (e.key === 'Enter') {
      e.preventDefault()
      this.handleSave()
    }
  }

  render () {
    let questions = this.props.questions
    let i = 0
    let questionList = questions.map((x) => {
      return (
        <TableRow key={i}>
          <TableCell>{i++}</TableCell>
          <TableCell>{x.name}</TableCell>
          <TableCell>{x.text}</TableCell>
          <TableCell>{x.description}</TableCell>
          <TableCell numeric>{x.yesValue}</TableCell>
          <TableCell numeric>{x.noValue}</TableCell>
          <TableCell>
            <Button id={x._id} color='primary' onClick={this.handleEdit.bind(this)}>
            Edit
            </Button>
          </TableCell>
          <TableCell>
            <Button id={x._id} color='primary' onClick={this.handleDelete.bind(this)}>
            Delete
            </Button>
          </TableCell>
        </TableRow>
      )
    })
    let index = questions.findIndex((x) => x._id === this.state.selected)
    if (index >= 0) {
      let x = questions[index]
      questionList[index] =
        <TableRow key={index}>
          <TableCell>{i++}</TableCell>
          <TableCell>
            <TextField
              type='string'
              id='name'
              value={this.state.name}
              onChange={this.handleChange.bind(this)}
              onKeyPress={this.catchEnter.bind(this)}
            />
          </TableCell>
          <TableCell>
            <TextField
              type='string'
              id='text'
              value={this.state.text}
              onChange={this.handleChange.bind(this)}
              onKeyPress={this.catchEnter.bind(this)}
            />
          </TableCell>
          <TableCell>
            <TextField
              type='string'
              id='description'
              value={this.state.description}
              onChange={this.handleChange.bind(this)}
              onKeyPress={this.catchEnter.bind(this)}
            />
          </TableCell>
          <TableCell>
            <TextField
              type='number'
              id='yesValue'
              value={this.state.yesValue}
              onChange={this.handleChange.bind(this)}
              onKeyPress={this.catchEnter.bind(this)}
            />
          </TableCell>
          <TableCell>
            <TextField
              type='number'
              id='noValue'
              value={this.state.noValue}
              onChange={this.handleChange.bind(this)}
              onKeyPress={this.catchEnter.bind(this)}
            />
          </TableCell>
          <TableCell>
            <Button color='primary' id={x._id} onClick={this.handleSave.bind(this)}>
            Save
            </Button>
          </TableCell>
          <TableCell>
            <Button color='primary' onClick={this.handleCancel.bind(this)}>
            Cancel
            </Button>
          </TableCell>
        </TableRow>
    }
    return (
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Text</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Yes Value</TableCell>
              <TableCell>No Value</TableCell>
              <TableCell>Edit</TableCell>
              <TableCell>Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {questionList}
          </TableBody>
        </Table>
      </Paper>
    )
  }
}

module.exports = withRouter(connect(mapStateToProps, mapDispatchToProps)(QuestionDisplay))
